const INIT = 'INIT';
const LOADING = 'LOADING';
const SUCCESS = 'SUCCESS';
const ERROR = 'ERROR';
let API_SUB_KEY
let API_URL
const url = window.location.host;
const PERSIST_KEY = 'root';
export let INSTRUMENTATION_KEY = '';


if (url.includes("localhost")) {
  API_URL = "https://sandbox.gateway.emerson.com/api/aslgrp/rqom-dev/v1"
  API_SUB_KEY = "b65ec736fac147fb90ca9d60df2eafcc"
}

// ======= Dev SandBox URL =========
if (url.includes("dev")) {
  API_URL = "https://sandbox.gateway.emerson.com/api/aslgrp/rqom-dev/v1"
  API_SUB_KEY = "b65ec736fac147fb90ca9d60df2eafcc"
}

// ======= Dev U1 SandBox URL =========
if (url.includes("u1")) {
  API_URL = "https://sandbox.gateway.emerson.com/api/aslgrp/rqom-devu1/v1"
  API_SUB_KEY = "b65ec736fac147fb90ca9d60df2eafcc"
}

// ======= Dev X4 SandBox URL =========
if (url.includes("x4")) {
  API_URL = "https://sandbox.gateway.emerson.com/api/aslgrp/rqom-devx4/v1"
  API_SUB_KEY = "b65ec736fac147fb90ca9d60df2eafcc"
}

// ======= Dev P1 SandBox URL =========
if (url.includes("p1")) {
  API_URL = "https://sandbox.gateway.emerson.com/api/aslgrp/rqom-devp1/v1"
  API_SUB_KEY = "b65ec736fac147fb90ca9d60df2eafcc"
}

// ======= Dev g6 SandBox URL =========
if (url.includes("g6")) {
  API_URL = "https://sandbox.gateway.emerson.com/api/aslgrp/rqom-devg6/v1"
  API_SUB_KEY = "b65ec736fac147fb90ca9d60df2eafcc"
}


// ======= INTG SandBox URL =======
if (url.includes("intg")) {
  API_URL = "https://sandbox.gateway.emerson.com/api/aslgrp/rqom-qa/v1"
  API_SUB_KEY = "07218b33415046c496e673e0ec4975c9"
  INSTRUMENTATION_KEY = 'c0e86777-9d53-49ed-9b80-d5949db802e3';
}

// ======= Stage SandBox URL =======
if (url.includes("stage")) {
  API_URL = "https://sandbox.gateway.emerson.com/api/aslgrp/rqom/v1/"
  API_SUB_KEY = "2949238082fc47a383b279b3374a2749"
}

// ======= PROD SandBox URL =======
if (url.includes("rapid-quote-order-tool.emerson.com")) {
  API_URL = "https://apim.gateway.emerson.com/api/aslgrp/rqom/v1/"
  API_SUB_KEY = "e48c28d6f155449abba76988ec2a2c0c"
}

export {
  INIT,
  LOADING,
  SUCCESS,
  ERROR,
  API_SUB_KEY,
  API_URL,
  PERSIST_KEY
};
