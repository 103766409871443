import React, { Component } from "react";
import Header from "../../components/header";
import Collapsible from "react-collapsible";
import PropTypes from "prop-types";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import SizingTable from "../../components/sizing-table";
import OrderTable from "../../components/order-table";
import QuoteTable from "../../components/quote-table";
import EnteredModelData from "../../components/entered-model-data";
import ModelManagement from "../../components/model-data/model-management";
import Popup from "../../components/shared/Popup";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import toastr from "toastr";
import { prepareUrlData } from "../../utils/helper";
import { getURLDetails, getUserDetails } from "../../utils/methods";
import { STATUS_IMPORT_EXCEL_SUCCESS, STATUS_EMAIL_NOTIFICATION_SUCCESS, STATUS_EMAIL_NOTIFICATION_ERROR, STATUS_IMPORT_PASTELIST_SUCCESS } from "../../store/model-data/constant";
import { STATUS_INIT_SUBMIT_SUCCESS } from "../../store/order/constant";
import validator from 'validator';
import Loader from '../../components/loader/loader';
import TextInput from "../../components/shared/TextInput";
import { GET_USER_DATA_SUCCESS } from "../../store/user/constant";

class AppHome extends Component {
  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  state = { // initial state
    loading: true,
    emailData: {
      email: '',
      isChecked: false
    },
    dispState: { // Boolean value for collapsible accordion 
      show: false,
      order: false,
      orderVisible: true,
      model: true,
      modelVisible: true,
      entered: false,
      enteredVisible: true,
      floatingBtns: true,
      reqNo: '',
      quickimport: false,
    },
    disable: { // Boolean value for disabling accordion 
      modelData: false,
      orderSizing: false,
      enteredModel: false
    },
    error: {},
    orderNo: '',
    quoteNo: '',
    onClosePopUp: true,
  };

  handleChange(event) { // function to get the checked data
    let value = '';
    if (event.target.hasOwnProperty('checked')) {
      value = event.target.checked
    } else {
      value = event.target.value;
    }
    const name = event.target.name;
    let error = Object.assign({}, this.state.error);
    let emailData = Object.assign({}, this.state.emailData);

    // Clear existing error on typing
    if (error.hasOwnProperty(name)) {
      delete error[name];
      this.setState({ error })
    }

    // Update the emailData
    emailData[name] = value;
    this.setState({ emailData });
  }

  componentWillMount() {
    const { login, UrlData, urlDetails } = this.props;
    let parsedUrlData = prepareUrlData(this.props.location.search);
    UrlData(parsedUrlData);
    login(urlDetails);
  }

  componentWillReceiveProps({ myEnteredModelData }) {
    let update = {};
    if ( // Checks the length of myEnteredModelData 
      myEnteredModelData.length !== this.props.myEnteredModelData.length &&
      myEnteredModelData.length > 0
    ) {
      let lineStatus = myEnteredModelData.find(x => {
        switch (x.LINE_STATUS) { // checks the line status
          case "ERROR_UPLOADING":
          case "LINE_ENTERED":
          case "READY_FOR_CONFIGURATION":
          case "CONFIGURATION_INCOMPLETE":
          case "CONFIGURATION_COMPLETED":
          case "PRICING":
          case "PROCESSING_LINES":
            return true;
          default:
            return false;
        }
      });
      if (Boolean(lineStatus)) {
        update = {
          dispState: { // checks if its a Boolean value 
            orderVisible: !Boolean(lineStatus),
            modelVisible: !Boolean(lineStatus),
            entered: true,
            enteredVisible: true
          }
        };
      }
      this.setState({
        loading: false,
        ...update
      });
    } else if (myEnteredModelData.length === 0) {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() { // unmounting to clear the session storage 
    sessionStorage.clear();
  }

  handleShow = () => {
    this.setState({ show: true })
  }

  handleClose = async e => { // Handle the close event of modal pop up
    this.setState({ show: false, error: {} });
  };

  handleClosePopUp = async e => { // Handle the close event of modal pop up
    this.setState({ onClosePopUp: false, error: {} });
  };

   handleQuickImport = () => {
    this.setState(
      (prevState) => ({
        dispState: {
          ...prevState.dispState,
          quickimport: true,
        },
      }),
    );
  }; 

  selectedCheckbox = (e) => {
    e.target.checked ? this.setState({ isChecked: true }) : this.setState({ isChecked: false })
  }

  componentDidUpdate(prevProps) {
    const { EnteredModelData, status, submitStatus, submittedData, userStatus,
      sessionFlag, emailStatus, onSaveError } = this.props; // passing props
    if (prevProps.onSaveError.status !== this.props.onSaveError.status) {
      // checks the current and previous onSaveError 
      if (this.props.onSaveError.status === "success" && !this.props.onSaveError.isRMT) {
        if (getUserDetails().SESSION_FLAG === 'Y') {
          EnteredModelData({ inlineStatus: this.props.onSaveError.status });
          this.setState({
            dispState: {
              ...this.state.dispState,
              entered: !this.state.dispState.entered,
              order: false,
              model: false,
              floatingBtns: false
            }
          });
        }
      }

      if (this.props.onSaveError.status === "failed" && !this.props.onSaveError.isRMT) {
        if (getUserDetails().SESSION_FLAG === 'Y') {
          EnteredModelData({ inlineStatus: this.props.onSaveError.status });
        }
      }
    }
    // checks the current and previous status 
    if (prevProps.status !== status) {
      if (status === STATUS_IMPORT_EXCEL_SUCCESS || status === STATUS_IMPORT_PASTELIST_SUCCESS) {
        this.setState({
          dispState: {
            ...this.state.dispState,
            entered: true,
            order: false,
            model: false,
            floatingBtns: false
          }
        });
      }
    }
    // checks the current and previous submitStatus 
    if (prevProps.submitStatus !== submitStatus) {
      if (submitStatus === STATUS_INIT_SUBMIT_SUCCESS) {
        this.setState({
          reqNo: submittedData
        })
      }
    }
    // checks the current and previous userStatus
    if (prevProps.userStatus !== userStatus) {
      if (userStatus === GET_USER_DATA_SUCCESS) {
        this.updateEmailData();
        if (getUserDetails().SESSION_FLAG === 'Y') {
          EnteredModelData({ inlineStatus: onSaveError });
          if (getURLDetails().I_APP_CODE === "ONT") {
            let orderNo = getUserDetails().ORDER_NUMBER;
            this.setState({
              orderNo
            })
          } else {
            let quoteNo = getUserDetails().QUOTE_NUMBER
            this.setState({
              quoteNo
            })
          }
        }
      }
    }
    // checks the current and previous sessionFlag 
    if (prevProps.sessionFlag !== sessionFlag) {
      if (sessionFlag === "N") {
        this.setState({
          dispState: {
            ...this.state.dispState,
            entered: false,
            order: false,
            model: false,
            floatingBtns: false
          },
          disable: {
            ...this.state.disable,
            modelData: true,
            orderSizing: true,
            enteredModel: true
          }

        });
      }
    }

    //checks the current and previous EmailStatus Flag
    if (prevProps.emailStatus !== emailStatus) {
      switch (emailStatus) {
        case STATUS_EMAIL_NOTIFICATION_SUCCESS:
          toastr.success("Notification is Activated Successfully", "Success");
          break;
        case STATUS_EMAIL_NOTIFICATION_ERROR:
          toastr.error("Notification is not Activated", "Error");
          break;
      }
    }
  }

  render() {
    const { dispState, loading, reqNo, emailData, error, orderNo, quoteNo, disable } = this.state; // passing state
    const { errorStatus, errorMessages } = this.props; // passing props
    let sessionFlagData = getUserDetails().SESSION_FLAG === 'N';

    return (
      <>
        {loading && (
          <div className="spinner-block">
            <div className="loader-block">
              <div className="loader" />
            </div>
          </div>
        )}

        <Loader />
        <div className="container-fluid">
          <div className="row">
            {/* Mounted Header component */}
            <Header />
          </div>

          {/* Preference Link  */}
          <div className="row accordian-row">
            <div className="w-100 text-right">
              <a onClick={this.handleShow} className="preference-link mr-2">Preference Link</a>
            </div>
            <p className="order-num text-right w-100 mr-3 mb-0">
              {getURLDetails().I_APP_CODE === "ONT" ? (
                <span className="order-text">
                  Order Number: {orderNo}
                </span>
              ) : (
                  <span className="order-text">
                    Quote Number: {quoteNo}
                  </span>
                )}
            </p>
            <p className="order-num text-right w-100 mr-3">
              {
                reqNo && (
                  <span className="order-text">
                    Request ID: {reqNo}
                  </span>
                )
              }
            </p>

            {/* ===== Collapsible for Order and Sizing ======== */}
            <div className="col-md-12">
              {dispState.orderVisible && !loading && (
                <Collapsible
                  open={dispState.order}
                  trigger="Order/Sizing Search"
                  handleTriggerClick={() => this.handleClick("order")}
                  triggerDisabled={disable.orderSizing}>

                  <Tabs defaultActiveKey="sizing" id="uncontrolled-tab-example">
                    <Tab eventKey="sizing" title="My Sizings">
                      {/* Mounted SizingTable component */}
                      <SizingTable
                        visibiltyCopySizing={this.VisibiltyCopySizing}
                      />
                    </Tab>
                    {getURLDetails().I_APP_CODE === "ONT" ?
                      <Tab eventKey="orders" title="My Orders">
                        {/* Mounted OrderTable component */}
                        <OrderTable visibiltyCopySizing={this.VisibiltyCopySizing} />
                      </Tab> :
                      <Tab eventKey="quote" title="My Quotes">
                        {/* Mounted QuoteTable component */}
                        <QuoteTable visibiltyCopySizing={this.VisibiltyCopySizing} />
                      </Tab>
                    }
                  </Tabs>
                </Collapsible>
              )}

              {/* ===== Collapsible for Model Data ======== */}
              {dispState.modelVisible && !loading && (
                <Collapsible
                  trigger="Model Data"
                  open={dispState.model}
                  handleTriggerClick={() => this.handleClick("model")}
                  triggerDisabled={disable.modelData}>
                  {/* Mounted ModelManagement component */}
                  <ModelManagement
                    exit={!dispState.model}
                    floatingBtnsVisible={dispState.floatingBtns}
                    visibleSections={this.VisibilitySection}
                    emailData={emailData}
                    quickimport={dispState.quickimport}
                    onQuickImport={this.handleQuickImport}
                  />
                </Collapsible>
              )}
              {/* ===== Collapsible for My Entered Model Data ======== */}
              {dispState.enteredVisible && !loading && (
                <Collapsible
                  trigger="My Entered Model Data"
                  open={dispState.entered}
                  handleTriggerClick={() => this.handleClick("entered")}
                  triggerDisabled={disable.enteredModel}
                >
                  {/* Mounted EnteredModelData component */}
                  <EnteredModelData
                    prepareNewRow={this.prepareAddNewRow}
                    visibleSections={this.VisibilitySection}
                    isQuickImport={this.state.dispState.quickimport}
                    onEdit={() => {
                      this.showEditData();
                    }}
                  />
                </Collapsible>
              )}
            </div>
          </div>

          {errorStatus &&
            errorStatus.length > 0 &&
            errorStatus.map(errorItem => {
              if (errorItem.errorCode === "Y") {
                return (
                  // To show the pop up message using modal
                  <Popup
                    key={errorItem.errorOn}
                    title={"Error"}
                    message={errorItem.errorMsg}
                    onOK={() => this.togglePopup(errorItem.errorOn)}
                    onCancel={() => this.togglePopup(errorItem.errorOn)}
                  />
                );
              }
            })}
          
          {/* Checks the session if N */}
          {
            // sessionFlagData && (
            //   <Modal show={this.state.onClosePopUp} onHide={this.handleClosePopUp}>
            //     {/* Modal Header */}
            //     <Modal.Header closeButton>
            //       <Modal.Title>Invalid Session</Modal.Title>
            //     </Modal.Header>

            //     {/* Modal Body */}
            //     <Modal.Body>
            //       {errorMessages && errorMessages.OutputParameters.O_ERROR_MSG}
            //     </Modal.Body>
            //     {/* Modal Footer */}
            //     <Modal.Footer>
            //       <Button variant="primary" onClick={this.handleClosePopUp}>
            //         Cancel
            //       </Button>
            //     </Modal.Footer>
            //   </Modal>
            // )
          }


        </div>

        {/* Email Confirmation Pop Up box */}
        <Modal show={this.state.show} onHide={this.handleClose}>
          {/* Modal Header */}
          <Modal.Header closeButton>
            <Modal.Title>Email Confirmation</Modal.Title>
          </Modal.Header>

          {/* Modal Body */}
          <Modal.Body>
            <p className="mb-1">
              <input name="isChecked" checked={emailData.isChecked} type="checkbox"
                className="notifyCheck" onChange={this.handleChange} /> Enable Email Notification</p>
            <div className="position-relative">
              <TextInput inputClass="notify-input form-input" name="email" label="Email Id" value={emailData.email}
                onChange={this.handleChange} error={error.email} onBlur={this.validateEmailData} />
            </div>
          </Modal.Body>
          {/* Modal Footer */}
          <Modal.Footer>
            {Object.keys(emailData).length > 0 && !validator.isEmail(emailData.email) && (
              <Button variant="primary" onClick={this.handleClose}>
                Cancel
            </Button>)}
            {Object.keys(emailData).length > 0 && validator.isEmail(emailData.email) && (
              <Button
                variant="primary"
                onClick={() => {
                  this.handleClose();
                  this.props.insertEmailData({
                    modelData: [],
                    urdData: [],
                    RMTurdData: [],
                    lineNumber: "",
                    flat: "",
                    holdData: [],
                    lineDetailsData: [],
                    emailData
                  })
                }}
              >
                Ok
            </Button>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  toggleStatusPopup = value => {
    this.setState({ onClosePopUp: value });
  };


  // Function to update the Email Data
  updateEmailData = () => {
    let emailData = Object.assign({}, this.state.emailData);
    let USER_EMAIL = getUserDetails().USER_EMAIL;
    let email = '';
    let isChecked = false;
    try {
      email = USER_EMAIL.split('|')[0];
      isChecked = USER_EMAIL.split('|')[1] === "Y" ? true : false
    } catch { }
    emailData.email = email;
    emailData.isChecked = isChecked;
    this.setState({
      emailData
    });
  }

  // ==== Function called when the down arrow is expanded ====
  handleClick = async section => {
    switch (section) { // switch case to check the section
      case "order": // when the case is order
        const { sizing, Orders, Quotes } = this.props;
        if (!this.state.dispState.order) {
          await sizing();
          { getURLDetails().I_APP_CODE === "ONT" ? await Orders() : await Quotes() }
        }
        this.setState({
          dispState: { // to close the accordions keeping order section On and Off
            ...this.state.dispState,
            order: !this.state.dispState.order,
            model: false,
            entered: false,
            floatingBtns: false
          }
        });
        break;
      case "model": // when the case is model
        this.setState({
          dispState: {  // to close the accordions keeping order model On and Off
            ...this.state.dispState,
            model: !this.state.dispState.model,
            order: false,
            entered: false,
            floatingBtns: !this.state.dispState.floatingBtns
          }
        });
        break;
      case "entered": // when the case is entered model data
        this.setState({
          dispState: {  // to close the accordions keeping entered data section On and Off
            ...this.state.dispState,
            entered: !this.state.dispState.entered,
            order: false,
            model: false,
            floatingBtns: false
          }
        });
        break;
      default:
        break;
    }
  };


  // ==== Function called to add new row in Model Data section ====
  prepareAddNewRow = () => {
    const { clearSaveErrors, onSaveError } = this.props;
    let dispState = {
      order: false,
      model: true,
      entered: false,
      orderVisible: true,
      modelVisible: true,
      enteredVisible: true,
      floatingBtns: true
    };
    this.setState({ dispState });
    clearSaveErrors(onSaveError.status);
    const initialRow = Object.assign({}, this.props.data.initialRow);
    let rows = [initialRow];
    this.props.updateRow({ rows, selectedBaseModelName: "" });
    this.props.clearBaseModels("ALL");
  };

  // Function called when the data is editted and collapse the accordion and making some accordion open 
  showEditData = () => {
    let dispState = {
      order: false,
      orderVisible: true,
      model: true,
      modelVisible: true,
      entered: false,
      enteredVisible: true,
      floatingBtns: true,
      lineNo: 1
    };
    this.setState({ dispState });
  };

  // Function called to when clicked entered model data and closing other accordions
  VisibilitySection = () => {
    let dispState = {
      orderVisible: false,
      modelVisible: false,
      enteredVisible: true,
      entered: true,
      floatingBtns: false
    };
    this.setState({ dispState });
  };

  // Function called to when clicked copy sizing  and closing other accordions
  VisibiltyCopySizing = () => {
    this.setState({
      dispState: {
        ...this.state.dispState,
        entered: !this.state.dispState.entered,
        order: false,
        model: false,
        floatingBtns: false
      }
    });
  };

  togglePopup = errorOn => {
    this.props.clearErrorStatus(errorOn);
  };

  // Function to validate the email 
  validateEmailData = (e) => {
    const { emailData } = this.state;
    let error = Object.assign({}, this.state.error);
    let isValid = validator.isEmail(emailData.email);
    if (isValid) {
      delete error['email']
    } else {
      error['email'] = 'Enter Valid Email Address';
    }
    this.setState({
      error
    })
  }
}

// ===== To declare that a prop is a specific JS primitive. ===== 
AppHome.propTypes = {
  login: PropTypes.func.isRequired,
  sizing: PropTypes.func.isRequired
};

export default AppHome;
