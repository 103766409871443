// import { log } from "util";
import React from 'react';

export const prepareUrlData = data => {
  let result = {};
  if (Object.keys(data).length > 0) {
    try {
      let params = new URLSearchParams(data);
      result = {
        I_USER_ID: params.get("urId"),
        I_HEADER_ID: params.get("hstHdrId"),
        I_SESSION_ID: params.get("sessionId"),
        I_ORG_ID: params.get("orgId"),
        I_RESP_ID: params.get("respid"),
        I_APP_CODE: params.get("appcode")
      };
      // if(result.I_APP_CODE ==="ONT") {
      //     result['I_APP_CODE'] = "OM"
      // }
    } catch { }
  }
  return result;
};

export const prepareModelNames = data => {
  let result = [];
  if (Object.keys(data).length > 0) {
    try {
      result = data.modelName.OutputParameters.O_MODEL_DETAILS.I_MODEL_DETAILS_ITEM.NAME.map(
        i => i.NAME
      );
    } catch { }
  }
  return result;
};

export const getCurrentModelName = (row) => {
  let result = {};
  let modelNames = row.modelNames;
  let modelString = row.modelString || "";
  let modelName = row.modelName || "";

  try {
    if (modelString !== "") {
      result = modelNames.find(i => i.MODEL_STRING === modelString.toUpperCase());
    } else if (modelName !== "") {
      result = modelNames.find(i => i.NAME === modelName);
    }
    getDivisionCode();
  } catch { }
  return result || {};
};

export const getDivisionCode = data => {
  let result = "";
  try {
    result = data.modalData.rows[0].modelNames[0].DIVISION_ID;
  } catch { }
  return result;
};

export const prepareLocationStatus = data => {
  let result = "";
  if (Object.keys(data).length > 0) {
    try {
      result = data.userLocation.OutputParameters.O_MANDATORY_INFO;
    } catch { }
  }
  return result;
};

export const prepareValidData = data => {
  let result = "";
  if (data && Object.entries(data).length > 0) {
    try {
      result =
        data.inlineValidation.OutputParameters.O_CFG_PARAMS_OUT
          .O_CFG_PARAMS_OUT_ITEM.CONFIG_STATUS;
    } catch (error) { }
  }
  return result;
};

export const prepareEnteredModelData = data => {
  let result = [];
  try {
    if (Object.entries(data.enteredModelData).length > 0) {
      let ResponseType =
        data.enteredModelData.OutputParameters.O_MODEL_TYPE_LIST
          .O_MODEL_TYPE_LIST_ITEM;
      if (Array.isArray(ResponseType) === false) {
        result = [
          data.enteredModelData.OutputParameters.O_MODEL_TYPE_LIST
            .O_MODEL_TYPE_LIST_ITEM
        ];
      } else {
        result = data.enteredModelData.OutputParameters.O_MODEL_TYPE_LIST.O_MODEL_TYPE_LIST_ITEM.map(
          item => item
        );
        var groupedModel = Object.values(result).reduce((o, value) => {
          if (!o[value.LINE_NUMBER]) {
            o[value.LINE_NUMBER] = [value];
          } else {
            o[value.LINE_NUMBER].push(value);
          }
          return o;
        }, {});
        result = Object.values(groupedModel).map(x => x[0]);
      }
    }
  } catch { }

  return result;
};

export const additionalTag = (row, id) => {
  let urdDetails = [...row.urdDetails.map(x => JSON.parse(JSON.stringify(x)))];
  if (urdDetails["0"] && urdDetails["0"].O_PSNODE_LIST.O_PSNODE_LIST_ITEM) {
    let repeat = urdDetails["0"].O_PSNODE_LIST.O_PSNODE_LIST_ITEM.filter(x => (x.NAME.startsWith("TAG-ITEMS") || x.PARENT === "TAG-ITEMS") && x.NODE_TYPE !== "OPTION" && x.NODE_TYPE !== "BUTTON FEATURE");

    let options = repeat.slice(0, 3).map(r => {
      let option = urdDetails["0"].O_PSNODE_LIST.O_PSNODE_LIST_ITEM.filter(x => x.PARENT === r.NAME && x.NODE_TYPE === "OPTION");
      return option.map(o => ({
        ...o,
        CHILD: undefined,
        NAME: `${o.NAME}`,
        PARENT: `${o.PARENT} ${(repeat.length / 3) + 1}`
      }))
    }).flat();
    repeat = repeat.slice(0, 3).map((x, index) => ({ ...x, NAME: `${x.NAME} ${(repeat.length / 3) + 1}`, CHILD: [] }));
    urdDetails["0"].O_PSNODE_LIST.O_PSNODE_LIST_ITEM = [...urdDetails["0"].O_PSNODE_LIST.O_PSNODE_LIST_ITEM, ...repeat, ...options];
  }
  return urdDetails;
}

export const prepareOnSaveProgressObject = () => {
  let result = {};
  result["status"] = "progress";
  result["title"] = "Validation in Progress";
  result["message"] = "Inline validation is in progress";
  return result;
};

export const prepareOnSaveErrorObject = data => {
  let result = {};
  result["status"] = "";
  try {
    if (data && Object.entries(data).length > 0) {
      result = {};
      let CONFIG_STATUS = "";
      let ResponseType = null;
      try {
        ResponseType =
          data.OutputParameters.O_CFG_PARAMS_OUT.O_CFG_PARAMS_OUT_ITEM;
      } catch { }

      if (ResponseType === null) {
        let O_ERROR_FLAG = data.OutputParameters.O_ERROR_FLAG;
        if (O_ERROR_FLAG === "Y") {
          result["status"] = "failed";
          result["title"] = data.OutputParameters.O_ERROR_CODE;
          result["message"] = data.OutputParameters.O_ERROR_MSG;
          return result;
        }
      } else if (ResponseType && Array.isArray(ResponseType) === false) {
        CONFIG_STATUS =
          data.OutputParameters.O_CFG_PARAMS_OUT.O_CFG_PARAMS_OUT_ITEM
            .CONFIG_STATUS;
      } else {
        CONFIG_STATUS =
          data.OutputParameters.O_CFG_PARAMS_OUT.O_CFG_PARAMS_OUT_ITEM[0]
            .CONFIG_STATUS;
      }
      if (CONFIG_STATUS === "FALSE") {
        result["status"] = "failed";
        result["title"] = "Error in Save";
        result["message"] = data.OutputParameters.O_CFG_MSG;
      } else {
        result["status"] = "success";
        result["title"] = "Success";
        result["message"] = "Saved successfully.";
      }
    }
  } catch { }
  return result;
};

export const prepareSelectedTabData = (selectedTab, apiData, fieldMapping) => {
  // Getting selected tab's data
  let tabApiData = apiData.find(r => r.NAME === selectedTab),
    result = [];

  if (tabApiData && tabApiData.O_PSNODE_LIST) {
    tabApiData = tabApiData.O_PSNODE_LIST;
    tabApiData = tabApiData.filter(i => i.NODE_TYPE !== "COMPONENT")
    // Assigining name field by comparing DESCRIPTION and fieldMapping(const)
    result = tabApiData.map(d => {
      if (fieldMapping[d.NAME]) {
        d.name = fieldMapping[d.NAME];
      }
      return d;
    });
  }
  // Removing the objects doesn't have name filed(ie filed mapping)
  // tabApiData = tabApiData.filter(d => d.name !== undefined);

  return result;
};

export const removeNull = o => {
  for (var key in o) {
    if (null === o[key] || "null" === o[key]) o[key] = "";
    if (typeof o[key] === "object") removeNull(o[key]);
  }
};

export const prepareInitialUrdDisplayData = (selectedTab, tabApiData, displayCofig, quantityTag, restoreState, fieldMapping) => {
  // Selected Tab's display config
  let XMTR_NAME = [];
  let textFeatureFilterted
  let tabDisplayConfig = displayCofig[selectedTab].filter(c => c.parent === "");
  let initialTabControls = [];
  tabDisplayConfig.map(section => {
    new Set([...Object.keys(section['display-features']), ...restoreState]).map(field => {
      //tabApiData = tabApiData.filter(c => c.NODE_TYPE !== "COMPONENT")
      let currentField = tabApiData.find(c => c.NAME === field);
      let groupLabel = (section['group-label'])
      let groupType = (section['group-type'])
      initialTabControls.push(prepareUrdField(currentField, groupLabel, groupType))
    })
    if (section['dynamic-display']) {
      initialTabControls = tabApiData.slice(1, quantityTag + 1).map(tagData => prepareUrdField(tagData));
    }
    if (section['display-features-rmtUrd']) {
      let quantityArray = new Array(quantityTag)
      if (quantityArray.map(tagData => prepareUrdField(tagData))) {
        new Set([...Object.keys(section['display-features-rmtUrd']), ...restoreState]).map(field => {
          let currentField = tabApiData.find(c => c.NAME === field);
          let groupLabel = (section['group-label'])
          let groupType = (section['group-type'])
          initialTabControls.push(prepareUrdField(currentField, groupLabel, groupType));

          if (currentField) {
            if (currentField.INITIAL_DISPLAY) {
              for (let i = 1; i < parseInt(currentField.INITIAL_DISPLAY); i++) {
                currentField.name = `${currentField.DESCRIPTION}${i}`;
                initialTabControls.push(prepareUrdField(currentField, groupLabel, groupType));
              }
            }
          }
        })
      }
    }

    if (selectedTab === "DPPLATE BORE DETAILS") {
      // let initialTabControls = [];
      textFeatureFilterted = tabApiData.filter(c => c.NODE_TYPE === "TEXT FEATURE")
    }
    if (section['dynamic-display-dpPlate']) {
      initialTabControls = textFeatureFilterted.slice(0, quantityTag).map(tagData => prepareUrdField(tagData));
    }
    if (selectedTab === "TAGS") {
      let textFeatureFilterted = tabApiData.filter(c => c.NODE_TYPE === "TEXT FEATURE")
      let hashResult = {};
      XMTR_NAME = Object.assign([], XMTR_NAME);
      textFeatureFilterted.forEach(item => {
        let tagNo = item.NAME.match(/(1ST_XMTR_NAME_TAG_\d+_)|(1ST_TUBE_NAME_TAG_\d+_)/g);
        if (tagNo != null) {
          tagNo = tagNo.map(x => parseInt(x.replace(/(1ST_XMTR_NAME_TAG_)|(1ST_TUBE_NAME_TAG_)|[_]/g, ''))).pop()
        } else tagNo = Infinity
        if (tagNo <= quantityTag) {
          hashResult[tagNo] = hashResult[tagNo] ? hashResult[tagNo] : [];
          hashResult[tagNo].push(item);
        }
      });
      XMTR_NAME = Object.values(hashResult).flat();
      initialTabControls = XMTR_NAME.map(tagData => prepareUrdField(tagData));
    }
  })
  return initialTabControls;
}


const prepareUrdField = (fieldData, groupLabel, groupType) => {
  let field = {};
  let dataofMax;
  if (fieldData) {
    // RMTInputPayload
    field.name = fieldData.name;
    field.initialvalue = fieldData.INITIAL_VALUE;
    if (fieldData.NAME === "WIRE" || fieldData.NAME === "SHORT" || fieldData.NAME === "NAME" || fieldData.NAME === "LONG" || fieldData.NAME === "PERM") {
      field.initialvalue = fieldData.INITIAL_VALUE === "0" ? "" : ""
    }
    let maxSelect = []
    if (fieldData.CHILD && fieldData.CHILD.map(item => parseInt(item.MAX_SELECTIONS) > 0)) {
      const maxSelect = fieldData.CHILD.map(item => parseInt(item.MAX_SELECTIONS));
      dataofMax = maxSelect.reduce((a, b) => a + b, 0)
    }
    if (fieldData.PARENT === "DPPLATE BORE DETAILS") {
      fieldData.NAME = `${fieldData.NAME}-DP`
      // field.name = `${field.name}-DP`
    }
    field.maxSelect = dataofMax;
    field.groupName = groupLabel;
    field.groupType = groupType;
    field.parentOf = fieldData.parentOf;
    field.maxChar = fieldData.MAX_CHARS;
    field.maxSelection = fieldData.MAX_SELECTIONS;

    let fieldType = '';
    switch (fieldData.NODE_TYPE) {
      case 'OPTION FEATURE':
        fieldType = 'dropdown';
        break;
      case 'DECIMAL FEATURE':
        fieldType = 'decimal';
        break;
      case 'INTEGER FEATURE':
        fieldType = 'integer';
        break;
      case 'BOOLEAN FEATURE':
        fieldType = 'boolean';
        break;
      case 'TEXT FEATURE':
        fieldType = 'text';
        break;
      case 'STATIC FEATURE':
        fieldType = 'static';
        break;
      case 'TEXT RMT FEATURE':
        fieldType = 'textJKey';
        break;
      case 'BUTTON FEATURE':
        fieldType = 'buttonFeature';
        break;
      default:
        fieldType = 'textForRMT';
        break;
    }
    field.type = fieldType;
    field.label = fieldData.DESCRIPTION || '';
    field.min = fieldData.MINIMUM;
    field.max = fieldData.MAXIMUM;
    if (fieldType === 'dropdown') {
      field.options = prepareUrdDropdownOptions(fieldData.CHILD || [])
    }
  }


  return field;
};

const prepareUrdDropdownOptions = child => {
  let options = [];
  child.map(item => {
    // Initializing blank object for option
    let option = {};
    option.label = item.DESCRIPTION;
    option.value = item.NAME;
    option.maxSelect = item.MAX_SELECTIONS

    // Adding the generated option to options-list
    options.push(option);
  });
  return options;
};

export const getReferenceField = (field, fieldMapping) => {
  let refField = "";
  Object.keys(fieldMapping).map(key => {
    if (fieldMapping[key] === field) {
      refField = key;
    }
  });
  return refField;
};

export const prepareChildUrdDisplayData = (
  selectedTab,
  parent,
  field,
  value,
  displayCofig,
  selectedTabData,
  restoreState,
  wireIndex
) => {

  let childFields = [];

  // Wire Validation Start
  let wireLength = 0;
  let _line = ((wireIndex - 1) * 5) + 1;
  // Wire Validation End

  // Selected Tab's display config
  let currentDisplayData = displayCofig[selectedTab].filter(
    c => c.parent === parent && c.value === value
  );


  currentDisplayData.map(section => {
    new Set([...Object.keys(section["display-features"]), ...Object.keys(restoreState)]).map(
      key => {
        key = key ? key : '';
        let currentField = selectedTabData.find(c => c.NAME === key);
        let groupLabel = section["group-label"];
        let groupType = section["group-type"];
        if (currentField) {
          currentField.parentOf = field;
          childFields.push(
            prepareUrdField(currentField, groupLabel, groupType)
          );
        }

        if (section["dynamic-display-rmt"] && key && key.startsWith("TAG-ITEMS")) {
          let currentField
          currentField = selectedTabData.find(c => c.NAME === section["value"]);
          if (currentField) {
            currentField = { ...currentField };
            currentField.parentOf = value;
            for (let i = 1; i <= parseInt(currentField.MAX_LINES); i++) {
              // let groupLabel = `Tag Number ${field[field.length - 1]}`;
              currentField.DESCRIPTION = `${currentField.TAG_LINE} ${i}`;
              if (value === currentField.parentOf && value === "WIRE") {
                currentField.name = `LINE_${_line}`;
                _line++;
              }
              else {
                currentField.name = `${currentField.DESCRIPTION} ${currentField.parentOf} ${groupLabel}`;
              }

              childFields.push(prepareUrdField(currentField, groupLabel));
            }
          }
        }
      }
    );
  });

  return childFields;
};

export const prepareOrderDetailsData = data => {
  let result = [];
  if (Object.keys(data).length > 0) {
    try {
      let intialData = data.OutputParameters.O_GS_ORD_LINE_REC.O_GS_ORD_LINE_REC_ITEM;
      let processedLineNos = [];
      if (Array.isArray(intialData) === false) {
        intialData = [intialData]
      }
      result = intialData.map((item, i) => {
        let lineNo = item.PO_LINE_NUMBER;
        let rowSpan = intialData.filter(d => d.PO_LINE_NUMBER === lineNo).length;
        item.id = item.PO_LINE_NUMBER;
        if (!processedLineNos.includes(lineNo)) {
          item.rowSpan = rowSpan;
          processedLineNos.push(lineNo);
        }
        return item;
      });
    } catch { }
  }
  return result;
};

export const prepareQuoteDetailsData = data => {
  let result = [];
  if (Object.keys(data).length > 0) {
    try {
      let intialData = data.OutputParameters.O_GS_QTE_LINE_REC.O_GS_QTE_LINE_REC_ITEM;
      let processedLineNos = [];
      if (Array.isArray(intialData) === false) {
        intialData = [intialData]
      }
      result = intialData.map((item, i) => {
        let lineNo = item.QUOTE_LINE_NUMBER;
        let rowSpan = intialData.filter(d => d.QUOTE_LINE_NUMBER === lineNo).length;
        item.id = item.QUOTE_LINE_NUMBER;
        if (!processedLineNos.includes(lineNo)) {
          item.rowSpan = rowSpan;
          processedLineNos.push(lineNo);
        }
        return item;
      });
    } catch { }
  }
  return result;
};
export const prepareModalNameParams = (modelString, id, modelName, rows) => {
  let I_MODEL_DETAILS_ITEM = rows
    .map(i => {
      return {
        MODEL_STRING: i.id === id ? modelString : i.modelString,
        NAME: i.id === id ? modelName : i.modelName || ""
      };
    })
    .filter(i => i.MODEL_STRING !== "");

  let I_MODEL_DETAILS = {
    I_MODEL_DETAILS_ITEM
  };
  return I_MODEL_DETAILS;
};

export const prepareKobDetails = data => {
  let result = [];
  if (Object.keys(data).length > 0) {
    try {
      result = data.kobData.OutputParameters.O_KOB_VALUE.O_KOB_VALUE_ITEM.map(
        i => ({ label: i.KOB_NAME, value: i.KOB_NAME })
      );
    } catch { }
  }
  return result;
};

export const prepareSourceType = data => {
  let result = [];
  if (Object.keys(data).length > 0) {
    try {
      result = data.sourceTypeData.OutputParameters.O_SOURCE_TYPE.O_SOURCE_TYPE_ITEM.map(
        i => ({ label: i.SOURCE_TYPE, value: i.SOURCE_TYPE })
      );
    } catch { }
  }
  return result;
};

export const preparePriceRollUpLines = data => {
  let result = [];
  try {
    let ResponseType = data.OutputParameters.O_ASSEMBLE_DETAILS.O_ASSEMBLE_DETAILS_ITEM;
    if (!Array.isArray(ResponseType)) {
      result = [ResponseType].map(item => item);
    } else {
      result = ResponseType.map(
        item => item
      );
    }
  } catch { }

  return result;
};

export const prepareAssembleTo = data => {
  let result = [];
  try {
    let ResponseType = data.OutputParameters.O_ASSEMBLE_TO.O_ASSEMBLE_TO_ITEM;
    if (!Array.isArray(ResponseType)) {
      result = [ResponseType].map(
        item => ({
          label: item.ASSEMBLE_TO,
          value: item.ASSEMBLE_TO
        })
      );
    } else {
      result = ResponseType.map(
        item => ({
          label: item.ASSEMBLE_TO,
          value: item.ASSEMBLE_TO
        })
      );
    }
  } catch { }

  return result;
};

export const preparePriceRollDropdown = data => {
  let result = [];
  try {
    result = data.OutputParameters.O_PRICE_ROLLUP.O_PRICE_ROLLUP_ITEM.map(
      item => ({
        label: item.PRICE_ROLLUP,
        value: item.PRICE_ROLLUP
      })
    );
  } catch { }

  return result;
};



export const prepareProjectNumber = data => {
  let result = [];
  if (Object.keys(data).length > 0) {
    try {
      result = data.projectNumberData.OutputParameters.O_PROJECT_NUM.O_PROJECT_NUM_ITEM.map(
        i => ({
          label: i.PROJECT_NUMBER,
          value: `${i.PROJECT_NUMBER}|${i.PROJECT_ID}`,
          id: i.PROJECT_ID
        })
      );
    } catch { }
  }
  return result;
};

export const prepareHoldDetails = data => {
  let result = [];
  if (Object.keys(data).length > 0) {
    try {
      result = data.holdDetailsData.OutputParameters.O_HOLD_NAME.O_HOLD_NAME_ITEM.map(
        i => ({
          label: i.HOLD_NAME,
          value: `${i.HOLD_NAME}|${i.TYPE_CODE}|${i.DESCRIPTION}|${i.HOLD_ID}`
        })
      );
    } catch { }
  }
  return result;
};

export const prepareTaskNumber = data => {
  let result = [];
  if (Object.keys(data).length > 0) {
    try {
      let ResponseType =
        data.taskNum.OutputParameters.O_TASK_NUM.O_TASK_NUM_ITEM;
      if (!Array.isArray(ResponseType)) {
        result = [data.taskNum.OutputParameters.O_TASK_NUM.O_TASK_NUM_ITEM].map(
          i => ({ label: i.TASK_NUMBER, value: i.TASK_NUMBER })
        );
      } else {
        result = data.taskNum.OutputParameters.O_TASK_NUM.O_TASK_NUM_ITEM.map(
          i => ({ label: i.TASK_NUMBER, value: i.TASK_NUMBER })
        );
      }
    } catch { }
  }
  return result;
};

export const preparePpltValue = data => {
  let result = "";
  if (Object.keys(data).length > 0) {
    try {
      result = data.pickPackTimeData.OutputParameters.O_PPLT_VALUE;
    } catch { }
  }
  return result;
};

export const prepareRepNumber = data => {
  let result = "";
  if (Object.keys(data).length > 0) {
    try {
      result = data.repLineNumberData.OutputParameters.O_REP_LINE_VALUE;
    } catch { }
  }
  return result;
};

export const prepareItemType = data => {
  let result = [];
  if (Object.keys(data).length > 0) {
    try {
      result = data.itemTypeData.OutputParameters.O_ITEM_TYPE.O_ITEM_TYPE_ITEM.map(
        i => ({ label: i.ITEM_TYPE, value: i.ITEM_TYPE })
      );
    } catch { }
  }
  return result;
};

export const prepareItemTypeFlag = data => {
  let result = "";
  try {
    result = data.itemTypeFlag.OutputParameters.O_DFF_FLAG;
  } catch { }
  return result;
};

export const prepareLineType = data => {
  let result = [];
  if (Object.keys(data).length > 0) {
    try {
      let ResponseType =
        data.lineTypeData.OutputParameters.O_LINE_TYPE.O_LINE_TYPE_ITEM;
      if (!Array.isArray(ResponseType)) {
        result = [ResponseType].map(i => ({
          label: i.LINE_TYPE,
          value: `${i.LINE_TYPE}|${i.LINE_TYPE_ID}`
        }));
      } else {
        result = data.lineTypeData.OutputParameters.O_LINE_TYPE.O_LINE_TYPE_ITEM.map(
          i => ({
            label: i.LINE_TYPE,
            value: `${i.LINE_TYPE}|${i.LINE_TYPE_ID}`
          })
        );
      }
    } catch { }
  }
  return result;
};

export const prepareLineNotesItem = data => {
  let result = [];
  if (Object.keys(data).length > 0) {
    try {
      result = data.lineNotesCategoryData.OutputParameters.O_LINE_NOTES.O_LINE_NOTES_ITEM.map(
        i => ({
          label: i.CATEGORY_NAME,
          value: `${i.CATEGORY_NAME}|${i.DATA_TYPE}`
        })
      );
    } catch { }
  }
  return result;
};

export const prepareLineNotesDataType = data => {
  let result = [];
  if (Object.keys(data).length > 0) {
    try {
      result = Object.values(data.lineNotesDataTypeData
        .OutputParameters.O_LINE_NOTES.O_LINE_NOTES_ITEM
        .reduce((o, i) => {
          if (i.DATA_TYPE !== null)
            o[i.DATA_TYPE] = { label: i.DATA_TYPE, value: i.DATA_TYPE };
          return o;
        }, {})
      );
    } catch { }
  }
  return result;
};

export const prepareModifier = data => {
  let ModifierResult = [];
  if (Object.keys(data).length > 0) {
    try {
      ModifierResult = data.modifier.OutputParameters.O_MODIFIER_LIST.O_MODIFIER_LIST_ITEM.map(
        i => ({
          label: `${i.MODIFIER_NAME.split('-')[0]}|${i.ARITHMETIC_OPERATOR}`,
          value: `${i.MODIFIER_NAME.split('-')[0]}|${i.ARITHMETIC_OPERATOR}`,
          operator: i.ARITHMETIC_OPERATOR,
          flag: i.MODIFIER_NAME.split('-')[1]
        })
      );
      ModifierResult = ModifierResult.filter(i => i.value !== "RMT PRICE DISCOUNT|NEWPRICE")
      ModifierResult = ModifierResult.sort((a, b) => descOrderComparator(a.label, b.label));
    } catch { }
  }
  return ModifierResult;
};

export const prepareCustomLocation = data => {
  let result = [];
  if (Object.keys(data).length > 0) {
    try {
      let ResponseType = data.consoleLocationData.OutputParameters.O_CONSOLE_LOC.O_CONSOLE_LOC_ITEM;
      if (!Array.isArray(ResponseType)) {
        result = [ResponseType]
          .map(i => ({ label: i.ADDRESS, value: `${i.ADDRESS}|${i.LOCATION}|${i.CUST_ACCOUNT_ID}` }));
      }
      else {
        result = data.consoleLocationData.OutputParameters.O_CONSOLE_LOC.O_CONSOLE_LOC_ITEM
          .map(i => ({ label: i.ADDRESS, value: `${i.ADDRESS}|${i.LOCATION}|${i.CUST_ACCOUNT_ID}` }));
      }
    }
    catch { }
  }
  return result;
}

export const parseModelDataForInlineValidation = (rows) => {
  let newRows = rows.rows.map((item, i) => {
    let newItem = {};
    newItem["QUANTITY"] = rows.rows[0].quantity;
    if (rows.divcode === "RAS") {
      newItem[`MODEL_NAME_${i + 1}`] = item.modelName;
      newItem[`MODEL_STRING_${i + 1}`] = item.modelString;
    }
    else {
      newItem[`MODEL_NAME_${i + 1}`] = item.modelName.toUpperCase();
      newItem[`MODEL_STRING_${i + 1}`] = item.modelString.toUpperCase();
    }

    return newItem;
  });
  if (rows.divcode === "MHM") {
    newRows.push({
      'END_USER_LOC': rows.rows[0].userlocation
    })
  }
  return newRows;
};

export const download = (url) => {
  window.open(url, '_blank');
}

export const htmlDecode = (input) => {
  var e = document.createElement('div');
  e.innerHTML = input;
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

export const updateInitialData = () => {

}

export const prepareErrorPopupMessage = (message) => {
  let result = [];
  if (message !== null) {
    let mergeTxt = message.replace(/<BR>/g, '<br/>')
      .replace(/<br>/g, '<br/>')
      .replace(/STATUS_EXISTING/g, '');
    let msg = mergeTxt.split('<br/>');
    let filteredMsg = msg.filter((item, i) => item !== "");
    result = filteredMsg.map(item => <span> {item} <br /></span>);
  }
  else result = [];
  return result;
}

export const prepareSubmitLinesData = data => {
  let result = "";
  if (data !== null) {
    result = data.OutputParameters.O_REQUEST_ID;
  }
  return result;
}

export const prepareConcurrentFlag = data => {
  let result = "";
  if (data !== null) {
    result = data.OutputParameters.O_CONC_PROG_FLAG;
  }
  return result;
};

// export const reOrderLineNumbersSequentially = () => {
//   ({ 'WIRE': [1, 10], 'SHORT': [11, 15], 'NAME': [16, 20], 'LONG': [21, 25], 'PERM': [26, 30] }, 'Tag Line', 'LINE', 'Tag Number')
// }

const generateNumberArrayBetweenRange = (start, end, limit = -1) => {
  let numbers = [];
  for (let i = start; i <= end; i++) {
    numbers.push(i);
  }
  numbers = limit > 0 ? numbers.slice(0, limit) : numbers;
  return numbers;
}

export const getMaxLineNo = (data) => {
  let maxLineNo = 0;
  try {
    let sortedLineNos = data.map(r => parseInt(r.LINE_NUMBER))
      .sort((a, b) => b - a);
    maxLineNo = sortedLineNos[0] || 0
  } catch { }
  return maxLineNo;
}

export const getAdditionalTagNos = (size, values) => {
  let results = [];
  values.map(value => {
    new Array(size).fill(0).map((_0, i) => {
      let tagObj = {
        "parent": `TAG-ITEMS${i === 0 ? '' : ` ${i + 1}`}`,
        "group-label": `Tag Number ${i >= 1 ? ` ${i + 1}` : '1'}`,
        "group-type": "frame",
        "value": value,
        "display-features": {
          ...(i === 0 ? { "ADDITION_BUTTON": 4 } : {}),
          [`TAG-ITEMS${i >= 1 ? ` ${i + 1}` : ''}`]: 1,
          [`ATTACH_TAG${i >= 1 ? ` ${i + 1}` : ''}`]: 2,
          [`OPTION_TAG${i >= 1 ? ` ${i + 1}` : ''}`]: 3,

        },
        "dynamic-display-rmt": {
          "active": true,
          "prefix": "Tag Line ",
          "count": "maxLines",
          "NODE_TYPE": "TEXT FEATURE"
        },
        "required-features": [
          "TAG-ITEMS"
        ]
      };
      results.push(tagObj);
      return _0;
    })
    return value;
  })
  return results;
}

const descOrderComparator = (a, b) => {
  if (a > b) {
    return -1;
  }
  if (b > a) {
      return 1;
  }
  return 0;
}
