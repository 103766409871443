//changes are made for the RFC AGL-ORA000231
import { SUCCESS, LOADING, ERROR, INIT } from "../../utils/constant";
import { InputPayload, RMTInputPayload, fieldMapping } from "../../utils/urd-template";
import toastr from "toastr"; //RT_002332 RT_002332 sudhakar
import {
  GET_MODELSTRING, GET_MODELSTRING_SUCCESS, GET_MODELSTRING_ERROR,
  GET_MODELNAME, GET_MODELNAME_SUCCESS, GET_MODELNAME_ERROR,
  CLEAR_MODELNAME, CLEAR_MODELNAME_SUCCESS, CLEAR_MODELNAME_ERROR,
  GET_WAREHOUSE, GET_WAREHOUSE_SUCCESS, GET_WAREHOUSE_ERROR,
  GET_MODIFIERS, GET_MODIFIERS_SUCCESS, GET_MODIFIERS_ERROR,
  GET_INLINE_VALIDATION, GET_INLINE_VALIDATION_SUCCESS, GET_INLINE_VALIDATION_ERROR,
  GET_INSERT_DATA, GET_INSERT_DATA_SUCCESS, GET_INSERT_DATA_ERROR,
  GET_USER_LOCATION, GET_USER_LOCATION_SUCCESS, GET_USER_LOCATION_ERROR,
  GET_URD_DATA, GET_URD_DATA_SUCCESS, GET_URD_DATA_ERROR,
  UPDATE_URD_INSERT_DATA, UPDATE_URD_INSERT_DATA_SUCCESS, UPDATE_URD_INSERT_DATA_ERROR,
  QUANTITY_CHANGE, QUANTITY_CHANGE_SUCCESS, QUANTITY_CHANGE_ERROR,
  GET_EDIT_DATA, GET_EDIT_DATA_SUCCESS, GET_EDIT_DATA_ERROR,
  CLEAR_URD_DATA, CLEAR_URD_DATA_SUCCESS, CLEAR_URD_DATA_ERROR,
  ADD_NEW_ROW, ADD_NEW_ROW_SUCCESS, ADD_NEW_ROW_ERROR,
  TASK_NUMBER, TASK_NUMBER_SUCCESS, TASK_NUMBER_ERROR,
  ADDITIONAL_INFO, ADDITIONAL_INFO_SUCCESS, ADDITIONAL_INFO_ERROR,
  GET_APPLY_HOLDS, GET_APPLY_HOLDS_SUCCESS, GET_APPLY_HOLDS_ERROR,
  LINE_PRICE, LINE_PRICE_SUCCESS, LINE_PRICE_ERROR,
  GET_RMT_URD_DATA, GET_RMT_URD_DATA_SUCCESS, GET_RMT_URD_DATA_ERROR,
  IMPORT_EXCEL_DATA, IMPORT_EXCEL_DATA_SUCCESS, IMPORT_EXCEL_DATA_ERROR,
  STATUS_IMPORT_EXCEL_INIT, STATUS_IMPORT_EXCEL_SUCCESS, STATUS_IMPORT_EXCEL_FAILED,
  STATUS_INSERT_SUCCESS, STATUS_INSERT_FAILED,
  STATUS_IMPORT_PASTELIST_INIT, STATUS_IMPORT_PASTELIST_SUCCESS, STATUS_IMPORT_PASTELIST_FAILED,
  STATUS_GET_MODELNAME_INIT, STATUS_GET_MODELNAME_FAILED, STATUS_GET_MODELNAME_SUCCESS,
  IMPORT_PASTELIST, IMPORT_PASTELIST_SUCCESS, IMPORT_PASTELIST_ERROR,
  STATUS_URD_INIT, STATUS_URD_SUCCESS, STATUS_URD_FAILED,
  ADD_ROWS,
  UPDATE_MODEL,
  UPDATE_ROW,
  UPDATE_URD,
  DELETE_ROWS,
  HANDLE_PICK_CHANGE,
  ADD_ROWS_APPLY_HOLDS,
  ADD_ROWS_LINE_NOTES,
  UPDATE_LINE_HOLD,
  UPDATE_HOLD_ROW,
  UPDATE_LINE_CATEGORY,
  UPDATE_LINE_NOTES_ROW,
  CLEAR_APPLY_HOLDS,
  ADDITION_BUTTON,
  UPDATE_INITIAL_DATA,
  UPDATE_URD_DISPLAY_DATA,
  CLEAR_BASE_MODELS_SUCCESS,
  CLEAR_SAVE_ERROR,
  EMAIL_NOTIFICATION, EMAIL_NOTIFICATION_SUCCESS, EMAIL_NOTIFICATION_ERROR,
  STATUS_EMAIL_NOTIFICATION_INIT, STATUS_EMAIL_NOTIFICATION_SUCCESS, STATUS_EMAIL_NOTIFICATION_ERROR,
  GET_PRICE_ROLLUP_DATA, GET_PRICE_ROLLUP_DATA_SUCCESS, GET_PRICE_ROLLUP_DATA_ERROR,
  GET_ASSEMBLETO, GET_ASSEMBLETO_SUCCESS, GET_ASSEMBLETO_ERROR,
  SAVE_PRICEROLL,
  SAVE_PRICEROLL_ERROR,
  SAVE_PRICEROLL_SUCCESS,
  UPDATE_REQUIRED_MULTI_LEVEL_MODEL,
  //RT_002332 RT_002332 sudhakar (+)
  COPY_MFG_NOTES,COPY_MFG_NOTES_SUCCESS,COPY_TAG_DTLS,COPY_TAG_DTLS_SUCCESS,COPY_CALIB_DTLS,COPY_CALIB_DTLS_SUCCESS
  //RT_002332 RT_002332 sudhakar (-)
} from "./constant";
import {
  prepareOnSaveErrorObject, prepareOnSaveProgressObject, preparePpltValue, removeNull,
  prepareKobDetails, prepareSourceType, prepareProjectNumber, prepareHoldDetails,
  prepareItemType, prepareLineType, prepareItemTypeFlag, prepareCustomLocation,
  prepareLineNotesDataType, prepareLineNotesItem, preparePriceRollUpLines, prepareAssembleTo,
  additionalTag, preparePriceRollDropdown, prepareRepNumber
} from "../../utils/helper";

const initialState = {
  phase: LOADING,
  modelString: null,
  divCode: null,
  wareHouse: null,
  modifier: null,
  inlineValidation: null,
  insertData: null,
  userLocation: null,
  saveErrors: {},
  quantity: "1",
  baseModels: [],
  modalData: {
    rows: [],
    lineNo: null,
    initialRow: {
      id: 0,
      modelString: "",
      modelName: "",
      warehouse: "",
      quantity: "1",
      modifier: "",
      operator: "",
      priceValue: "",
      modelStringEnable: true,
      userlocation: "",
      sizingId: "",
      modelNames: [],
      urdData: [{}],
      initialUrdData: {},
      RMTurdData: {
      },
      urdDetails: [], // added urdDetails inside row
      urdTabDetailsOnClear: {},
      urdDisplayData: {},
      InputPayload: {},
      RMTInputPayload: {},
      linePrice: "",
      additionalDataDetails: {
        kobData: [],
        sourceTypeData: [],
        //priceRollUpData: ["Yes", "No"],
        projectNumberData: [],
        holdDetailsData: [],
        pickPackTimeData: [],
        itemTypeData: [],
        lineTypeData: [],
        itemTypeFlagData: "",
        consoleLocationData: [],
        lineNotesCategoryData: [],
        lineNotesDataTypeData: [],
        repLineNumberData: [],
      },
      additionalData: {
        qscausecode: "",
        kobDetails: "",
        sourceType: "",
        priceRollUp: "",
        projectNum: "",
        taskNum: "",
        itemType: "",
        holdDetails: "",
        customerpolineNum: "",
        lineType: "",
        shippingInstructions: "",
        packingInstructions: "",
        assembleTo: "",
        customsvalue: "",
        customerRef: "",
        pickPackTime: "",
        lineId: "",
        itemTypeFlag: "",
        shipSet: "",
        fullfillmentSet: "",
        consoleLocationId: "",
        consoleCustomId: "",
        consoleAddress: "",
        userItemDescription: "", //added new field for RT_002629---,
        repLineNumber: "" //added new field for RT0006636
      },
    },
    userlocations: [],
    location: "",
    selectedBaseModelName: "",
    baseModelNew: {},
    ApplyHoldRows: [],
    initialRowApplyHold: {
      id: 0,
      holdName: "",
      holdId: "",
      holdType: "",
      description: "",
      holdUntillDate: "",
      holdComments: ""
    },
    LineNotesRows: [],
    initialRowLineNotes: {
      id: 0,
      lineNoSeq: "",
      lineCategory: "",
      lineNotesTitle: "",
      lineNotesDescription: "",
      lineNotesDataType: "",
      lineNotesComments: ""
    },
    applyHolds: null
  },
  status: '',
  emailStatus: '',
  priceRollUpData: [],
  assembleToData: [],
  priceRollUpDataLines: [],
  priceRollUpValues: [],
  requiredMultiLevelModel: false,
};


// ModalDataStore Reducer

export function modalDataStore(state = initialState, action) {
  switch (action.type) {
    case GET_MODELSTRING:
    case CLEAR_MODELNAME:
    case GET_WAREHOUSE:
    case GET_MODIFIERS:
    case GET_INSERT_DATA:
    case UPDATE_URD_INSERT_DATA:
    case GET_USER_LOCATION:
    case QUANTITY_CHANGE:
    case GET_EDIT_DATA:
    case CLEAR_URD_DATA:
    case ADD_NEW_ROW:
    case TASK_NUMBER:
    case ADDITIONAL_INFO:
    case GET_APPLY_HOLDS:
    case GET_APPLY_HOLDS:
    case LINE_PRICE:
    case GET_RMT_URD_DATA:
    case GET_PRICE_ROLLUP_DATA:
    case GET_ASSEMBLETO:
    case SAVE_PRICEROLL:
      return {
        ...state,
        phase: LOADING
      };
    case GET_MODELSTRING_SUCCESS:
      return {
        ...state,
        phase: SUCCESS,
        modelString: action.data,
        error: null
      };
    case GET_MODELSTRING_ERROR:
    case CLEAR_MODELNAME_ERROR:
    case GET_WAREHOUSE_ERROR:
    case GET_MODIFIERS_ERROR:
    case UPDATE_URD_INSERT_DATA_ERROR:
    case GET_USER_LOCATION_ERROR:
    case QUANTITY_CHANGE_ERROR:
    case CLEAR_URD_DATA_ERROR:
    case ADD_NEW_ROW_ERROR:
    case TASK_NUMBER_ERROR:
    case ADDITIONAL_INFO_ERROR:
    case LINE_PRICE_ERROR:
    case GET_APPLY_HOLDS_ERROR:
    case GET_RMT_URD_DATA_ERROR:
    case GET_EDIT_DATA_ERROR:
    case GET_PRICE_ROLLUP_DATA_ERROR:
    case GET_ASSEMBLETO_ERROR:
    case SAVE_PRICEROLL_ERROR:
      return {
        ...state,
        phase: ERROR,
        error: action.error
      };

    case GET_URD_DATA_ERROR:
      return {
        ...state,
        phase: ERROR,
        error: action.error,
        status: STATUS_URD_FAILED

      }

    case GET_MODELNAME:
      return {
        ...state,
        phase: LOADING,
        status: STATUS_GET_MODELNAME_INIT
      };

    case GET_URD_DATA:
      return {
        ...state,
        phase: LOADING,
        status: STATUS_URD_INIT

      }
    case GET_MODELNAME_SUCCESS: {
      let baseModelsForModelNames = action.data.baseModels.map(i => { // fetching only model name using map operator
        return {
          NAME: i.MODEL_NAME,
          DIVISION_ID: i.DIVISION_ID
        };
      });
      let newRows = [...state.modalData.rows];
      // Find Index which returns the index where the id and action id equals
      let rowIndex = newRows.findIndex(x => x.id === action.data.id);
      let row = {
        ...newRows[rowIndex],
        modelNames: [...action.data.modelNames, ...baseModelsForModelNames]
      };
      newRows[rowIndex] = row;
      return {
        ...state,
        phase: SUCCESS,
        modalData: {
          ...state.modalData,
          rows: newRows
        },
        baseModels: action.data.baseModels,
        error: null,
        status: STATUS_GET_MODELNAME_SUCCESS
      };
    }
    case GET_MODELNAME_ERROR:
      return {
        ...state,
        phase: ERROR,
        error: action.error,
        status: STATUS_GET_MODELNAME_FAILED
      };
    case CLEAR_MODELNAME_SUCCESS: {
      let newRows = [...state.modalData.rows];
      // Find Index which returns the index where the id and action.payload equals
      let rowIndex = newRows.findIndex(x => x.id === action.payload);
      let row = {
        ...newRows[rowIndex],
        modelNames: [],
        modelName: ""
      };
      newRows[rowIndex] = row;
      return {
        ...state,
        phase: SUCCESS,
        modalData: {
          ...state.modalData,
          rows: newRows
        },
        error: null
      };
    }
    case CLEAR_BASE_MODELS_SUCCESS:
      let updatedBaseModels = Object.assign([], state.baseModels);
      if (action.id === "ALL") {
        updatedBaseModels = [];
      } else {
        // filters method craetes a new array when the id's doest not match with action.id
        updatedBaseModels = updatedBaseModels.filter(m => m.id !== action.id);
      }

      return {
        ...state,
        phase: SUCCESS,
        baseModels: updatedBaseModels,
        error: null
      };
    case GET_WAREHOUSE_SUCCESS:
      return {
        ...state,
        phase: SUCCESS,
        wareHouse: action.data,
        error: null
      };

    case GET_MODIFIERS_SUCCESS:
      return {
        ...state,
        phase: SUCCESS,
        modifier: action.data,
        error: null
      };

    case GET_INLINE_VALIDATION:
      let saveErrorProgress = prepareOnSaveProgressObject();
      return {
        ...state,
        phase: LOADING,
        saveErrors: saveErrorProgress
      };
    case GET_INLINE_VALIDATION_SUCCESS:
      let saveErrors = prepareOnSaveErrorObject(action.payload.data); //collect it from action.data
      saveErrors.isRMT = Boolean(action.payload.URDClick);
      return {
        ...state,
        phase: SUCCESS,
        inlineValidation: action.payload.data,
        error: null,
        saveErrors
      };
    case GET_INLINE_VALIDATION_ERROR:
      return { ...state, phase: ERROR, error: action.error };
    case GET_INSERT_DATA_SUCCESS:
      return {
        ...state,
        phase: SUCCESS,
        insertData: action.data,
        error: null,
        status: STATUS_INSERT_SUCCESS
      };

    case GET_INSERT_DATA_ERROR:
      return {
        ...state,
        phase: ERROR,
        error: action.error,
        status: STATUS_INSERT_FAILED
      };

    case IMPORT_EXCEL_DATA:
      return {
        ...state,
        phase: LOADING,
        status: STATUS_IMPORT_EXCEL_INIT
      };

    case IMPORT_EXCEL_DATA_SUCCESS:
      return {
        ...state,
        phase: SUCCESS,
        insertData: action.data,
        error: null,
        status: STATUS_IMPORT_EXCEL_SUCCESS
      };

    case IMPORT_EXCEL_DATA_ERROR:
      return {
        ...state,
        phase: ERROR,
        error: action.error,
        status: STATUS_IMPORT_EXCEL_FAILED
      };

    case IMPORT_PASTELIST:
      return {
        ...state,
        phase: LOADING,
        status: STATUS_IMPORT_PASTELIST_INIT
      };

    case IMPORT_PASTELIST_SUCCESS:
      return {
        ...state,
        phase: SUCCESS,
        insertData: action.data,
        error: null,
        status: STATUS_IMPORT_PASTELIST_SUCCESS
      };

    case IMPORT_PASTELIST_ERROR:
      return {
        ...state,
        phase: ERROR,
        error: action.error,
        status: STATUS_IMPORT_PASTELIST_FAILED
      };

    case EMAIL_NOTIFICATION:
      return {
        ...state,
        phase: LOADING,
        emailStatus: STATUS_EMAIL_NOTIFICATION_INIT
      };

    case EMAIL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        phase: SUCCESS,
        insertData: action.data,
        error: null,
        emailStatus: STATUS_EMAIL_NOTIFICATION_SUCCESS
      };

    case EMAIL_NOTIFICATION_ERROR:
      return {
        ...state,
        phase: ERROR,
        error: action.error,
        emailStatus: STATUS_EMAIL_NOTIFICATION_ERROR
      };

    case UPDATE_URD_INSERT_DATA_SUCCESS:
      const { field, value } = action.payload;
      let InputPayload = [...InputPayload]
      let RMTInputPayload = [...RMTInputPayload]
      InputPayload[field] = value;
      RMTInputPayload[field] = value;

      return {
        ...state,
        modalData: {
          ...state.modalData,
          InputPayload: InputPayload,
          RMTInputPayload: RMTInputPayload
        },
        phase: SUCCESS,
        error: null
      };
    case GET_URD_DATA_SUCCESS:
      {
        let rows = state.modalData.rows;
        let rowId = action.id;
        rows = rows.map(row => {
          if (row.id === rowId) {
            row = { ...row }
            row.urdDetails = action.data.urdSimplifiedData
          }
          return row;
        })
        return {
          ...state,
          modalData: {
            ...state.modalData,
            rows
          },
          phase: SUCCESS,
          // urdDetails: action.data,
          error: null,
          status: STATUS_URD_SUCCESS,
          urdFetchId: action.id
        };
      }

    case GET_USER_LOCATION_SUCCESS:
      return {
        ...state,
        phase: SUCCESS,
        userLocation: action.data,
        error: null
      };

    case CLEAR_SAVE_ERROR:
      return {
        ...state,
        phase: INIT,
        saveErrors: {}
      };

    case QUANTITY_CHANGE_SUCCESS: {
      let { value } = action.payload;
      let rows = [...state.modalData.rows];
      for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
        let urds = [...rows[rowIndex].urdData];
        // urds = urds[rowIndex];

        value = parseInt(value);
        if (urds.length <= value) { //add more
          urds = [
            ...urds,
            ...(Array(value - urds.length).fill({ ...state.modalData.rows[rowIndex].urdData[0] }))
          ]
        } else { //delete
          urds = urds.slice((value - 1), urds.length)
        }
        rows[rowIndex] = { ...rows[rowIndex], urdData: urds };
      }
      return {
        ...state,
        phase: SUCCESS,
        quantity: value,
        modalData: {
          ...state.modalData,
          rows
        }
      }
    }

    case ADD_ROWS:
      return {
        ...state,
        modalData: {
          ...state.modalData,
          rows: action.payload
        }
      };
    case UPDATE_MODEL:
    case DELETE_ROWS:
      return {
        ...state,
        modalData: {
          ...state.modalData,
          ...JSON.parse(JSON.stringify(action.payload))
        }
      };
    case UPDATE_URD: {
      let { id, urd, unit = 0 } = action.payload;
      let rows = [...state.modalData.rows];
      let rowIndex = rows.findIndex(x => x.id === id);
      let urds = [...rows[rowIndex].urdData];
      urds[unit] = urd;
      rows[rowIndex] = { ...rows[rowIndex], urdData: urds };
      return {
        ...state,
        modalData: {
          ...state.modalData,
          rows
        }
      };
    }

    case UPDATE_ROW: {
      let infoId = action.payload.id || 0;
      //let additionalData = action.payload.additionalData;
      let rows = [...state.modalData.rows];
      let row = rows[infoId];
      row.additionalData = action.payload.additionalData;
      rows[infoId] = row;
      return {
        ...state,
        modalData: {
          ...state.modalData,
          rows
        }
      };
    }

    case GET_EDIT_DATA_SUCCESS:
      var modalData = JSON.parse(JSON.stringify(state.modalData.initialRow)),
        selectedBaseModelName,
        baseModelNew;
      var lineNo = action.data.model.lineNo;
      var urdData = action.data.urd.response;
      var modelData = action.data.model.response;
      // var quantityCheck = action.data.quantityCheck
      modelData =
        modelData.OutputParameters.O_MODEL_TYPE_LIST.O_MODEL_TYPE_LIST_ITEM;
      if (!Array.isArray(modelData)) modelData = [modelData];
      if (urdData.OutputParameters.O_URD_LIST
        && urdData.OutputParameters.O_URD_LIST.O_URD_LIST_ITEM) {
        // It is having normal URD data
        urdData = urdData.OutputParameters.O_URD_LIST.O_URD_LIST_ITEM || [{}];
        if (!Array.isArray(urdData)) {
          urdData = [urdData];
        }
      }
      else if (urdData.OutputParameters.O_RMT_URD_LIST
        && urdData.OutputParameters.O_RMT_URD_LIST.O_RMT_URD_LIST_ITEM) {
        // It is having RMT URD data 
        urdData = urdData.OutputParameters.O_RMT_URD_LIST.O_RMT_URD_LIST_ITEM || [{}];
        if (!Array.isArray(urdData)) {
          urdData = [urdData];
        }
      } else {
        // ie the URD data is not available
        urdData = [];
      }

      if (urdData.length > 0) {
        urdData.forEach(removeNull);
        // urdData = [urdData]
      }

      let organizedByBaseModel
      organizedByBaseModel = Object.values(urdData).reduce((o, x) => {
          if (o[x.BASE_MODEL] !== undefined) o[x.BASE_MODEL].push(x);
          else o[x.BASE_MODEL] = [x];
          return o;
      }, {})
    
      urdData = Object.values(organizedByBaseModel);
      
      // ======== Do not delete this lines 

      // let groupedUrd = []
      // for (let i = 0; i < urdData.length; i += quantityCheck) {
      //   let urdDataArrayCheck = urdData.slice(i, (quantityCheck + (i)))
      //   groupedUrd.push(urdDataArrayCheck)
      // }
      // urdData = groupedUrd;

      modelData = modelData.filter(x => x.LINE_NUMBER === `${lineNo}`);
      let blankModelStringCount = modelData.filter(r => !r.MODEL_STRING).length;
      let modelDataCount = modelData.length;
      if (blankModelStringCount === 0) {
        // CMF
        selectedBaseModelName = '';

      } else {
        if (blankModelStringCount === modelDataCount && modelDataCount === 1) {
          // Flat
          selectedBaseModelName = 'flat';
        } else {
          // FT Model
          selectedBaseModelName = modelData[0].MODEL_NAME.toString().trim();
        }
      }

      if (selectedBaseModelName) {
        urdData = [...urdData]
      }
      baseModelNew =
        state.baseModels.find(x => x.MODEL_NAME === selectedBaseModelName) || {};

      modalData.rows = modelData.map((x, i) => {
        removeNull(x);
        let modelString = '';
        let modelStringEnable = true;
        if (selectedBaseModelName === 'flat') {
          modelString = x.MODEL_NAME.toString().trim();
        } else {
          if (i === 0) {
            modelString = x.MODEL_STRING;
            modelStringEnable = modelString ? true : false;
          } else {
            modelString = x.MODEL_STRING ? x.MODEL_STRING : x.MODEL_NAME.toString().trim();
          }
        }
        return {
          id: i,
          modelString,
          modelName: x.MODEL_NAME,
          warehouse: x.WAREHOUSE,
          quantity: x.ITEM_QUANTITY,
          modifier: x.MANUAL_MODIFIER_BASE_MDL,
          operator: x.ADJUSTMENT_METHOD,
          priceValue: x.PRICE_ADJUSTMENT,
          modelStringEnable,
          userlocation: x.END_USER_LOC,
          sizingId: x.SIZING_REFERENCE_ID,
          urdData: urdData[i] ? urdData[i] : [{}],
          additionalData: {
            qscausecode: x.QS_CAUSE_CODE,
            kobDetails: x.KOB3,
            sourceType: x.SOURCE_TYPE,
            projectNum: x.PROJECT_NUMBER,
            taskNum: x.TASK_NUMBER,
            itemType: x.INV_ITEM_DFF,
            holdDetails: "",
            //customerpolineNum: x.CUST_PO_LINE, //changes are made as a part of RFC AGL 231 to pick values for submodels
            customerpolineNum: (i == 0) ? x.CUST_PO_LINE : (i == 1) ? x.CUST_PO_LINE_2 :  (i == 2) ? x.CUST_PO_LINE_3 : (i == 3) ? x.CUST_PO_LINE_4 : x.CUST_PO_LINE_5,
            priceRollUp: x.PRICE_ROLL_UP,
            lineType: x.LINE_TYPE,
            shippingInstructions: x.SHIP_INSTRUCTIONS,
            packingInstructions: x.PACK_INSTRUCTIONS,
            assembleTo: x.ASSEMBLE_TO,
            customsvalue: x.CUSTOMSVALUE,
            //customerRef: (i == 0)? x.CUSTOMER_REF : x.CUSTOMER_REF_4,
            customerRef: (i == 0) ? x.CUSTOMER_REF : (i == 1) ? x.CUSTOMER_REF_2 :  (i == 2) ? x.CUSTOMER_REF_3 :  (i == 3) ?  x.CUSTOMER_REF_4 : x.CUSTOMER_REF_5,
            pickPackTime: x.PICK_PACK_LEAD_TIME,
            lineId: x.LINE_TYPE_ID,
            itemTypeFlag: "",
            shipSet: x.SHIP_SET,
            fullfillmentSet: x.FULFILLMENT_SET_NAME,
            consoleLocationId: x.CONSOL_LOCATION_ID,
            consoleCustomId: x.CONSOL_CUST_ID,
            consoleAddress: x.CONSOL_LOC_ADDRESS,
            //userItemDescription: x.USER_ITEM_DESCRIPTION //added new field for RT_002629
            userItemDescription: (i == 0)? x.USER_ITEM_DESCRIPTION : (i == 1) ? x.USER_ITEM_DESCRIPTION_2 :  (i == 2) ? x.USER_ITEM_DESCRIPTION_3 : (i == 3) ? x.USER_ITEM_DESCRIPTION_4 : x.USER_ITEM_DESCRIPTION_5,
            repLineNumber: x.REP_LINE_NUMBER
          },
          urdDisplayData: {},
          additionalDataDetails: {
            kobData: [],
            sourceTypeData: [],
            //priceRollUpData: [],
            projectNumberData: [],
            holdDetailsData: [],
            pickPackTimeData: [],
            repLineNumberData: [],
            itemTypeData: [],
            lineTypeData: [],
            itemTypeFlagData: "",
            consoleLocationData: [],
            lineNotesCategoryData: [],
            lineNotesDataTypeData: []
          }
        };
      });

      return {
        ...state,
        phase: SUCCESS,
        modalData: {
          ...state.modalData,
          ...modalData,
          lineNo,
          edit: true,
          userlocations: [],
          location: "",
          selectedBaseModelName,
          baseModelNew,
          error: null
        },
        baseModels: []
      };

    case CLEAR_URD_DATA_SUCCESS:
      return {
        ...state,
        phase: SUCCESS,
        urdDetails: []
      };
    case ADD_NEW_ROW_SUCCESS:
      return {
        ...state,
        phase: SUCCESS,
        modalData: {
          ...state.modalData,
          ...modalData,
          lineNo: action.data
        }
      };
    case TASK_NUMBER_SUCCESS:
      return {
        ...state,
        phase: SUCCESS,
        taskNum: action.data
      };

    case HANDLE_PICK_CHANGE:
      return {
        ...state,
        phase: SUCCESS,
        pickPackTime: action.payload
      };

    case ADDITIONAL_INFO_SUCCESS: {
      let responseData = action.payload.data;
      let rowId = state.modalData.rows.findIndex(r => r.id === action.payload.id);
      let rows = [...state.modalData.rows];
      let additionalDataDetails = {
        kobData: prepareKobDetails({ kobData: responseData }),
        sourceTypeData: prepareSourceType({ sourceTypeData: responseData }),
        //priceRollUpData: preparePriceRollUpLines(state.modalData.rows[rowId].additionalDataDetails.priceRollUpData),
        projectNumberData: prepareProjectNumber({ projectNumberData: responseData }),
        holdDetailsData: prepareHoldDetails({ holdDetailsData: responseData }),
        pickPackTimeData: preparePpltValue({ pickPackTimeData: responseData }),
        repLineNumberData: prepareRepNumber({repLineNumberData: responseData }),
        lineTypeData: prepareLineType({ lineTypeData: responseData }),
        itemTypeFlagData: prepareItemTypeFlag({ itemTypeFlag: responseData }),
        lineNotesCategoryData: prepareLineNotesItem({ lineNotesCategoryData: responseData }),
        lineNotesDataTypeData: prepareLineNotesDataType({ lineNotesDataTypeData: responseData }),
        consoleLocationData: prepareCustomLocation({ consoleLocationData: responseData })
      };

      rows[rowId] = {
        ...rows[rowId],
        additionalDataDetails,
        additionalData: {
          ...state.modalData.rows[rowId].additionalData,
          itemTypeFlag: additionalDataDetails.itemTypeFlagData
        }
      };
      return {
        ...state,
        phase: SUCCESS,
        modalData: {
          ...state.modalData,
          rows
        }
      };
    }

    case ADD_ROWS_APPLY_HOLDS:
      return {
        ...state,
        modalData: {
          ...state.modalData,
          ApplyHoldRows: action.payload
        }
      };

    case GET_APPLY_HOLDS_SUCCESS:
      return {
        ...state,
        phase: SUCCESS,
        applyHolds: action.data,
        error: null
      };

    case LINE_PRICE_SUCCESS:
      return {
        ...state,
        phase: SUCCESS,
        linePrice: action.data,
        error: null
      };

    case ADD_ROWS_LINE_NOTES:
      return {
        ...state,
        modalData: {
          ...state.modalData,
          LineNotesRows: action.payload
        }
      };

    case GET_PRICE_ROLLUP_DATA_SUCCESS:
      return {
        ...state,
        phase: SUCCESS,
        priceRollUpDataLines: preparePriceRollUpLines(action.data),
        error: null
      };
    case GET_ASSEMBLETO_SUCCESS:
      return {
        ...state,
        phase: SUCCESS,
        assembleToData: prepareAssembleTo(action.data),
        priceRollUpValues: preparePriceRollDropdown(action.data),
        error: null
      };
    case SAVE_PRICEROLL_SUCCESS:
      return {
        ...state,
        phase: SUCCESS,
        priceRollUpData: action.payload,
        error: null
      };
    case UPDATE_LINE_HOLD:
      let responseData = action.payload.initialRowApplyHold.holdName;
      let rowId = state.modalData.ApplyHoldRows.findIndex(r => r.id === action.payload.id);
      let ApplyHoldRows = [...state.modalData.ApplyHoldRows];
      ApplyHoldRows[rowId] = {
        ...ApplyHoldRows[rowId],
        ...state.modalData.ApplyHoldRows[rowId],
        holdName: responseData,
        holdType: responseData.split('|')[1],
        description: responseData.split('|')[2],
        holdId: responseData.split('|')[3],
      }

      return {
        ...state,
        phase: SUCCESS,
        modalData: {
          ...state.modalData,
          ApplyHoldRows
        }
      };

    case UPDATE_HOLD_ROW: {
      let rowId = state.modalData.ApplyHoldRows.findIndex(r => r.id === action.payload.id);
      let ApplyHoldRows = [...state.modalData.ApplyHoldRows];
      ApplyHoldRows[rowId] = {
        ...ApplyHoldRows[rowId],
        ...state.modalData.ApplyHoldRows[rowId],
        holdComments: action.payload.initialRowApplyHold.holdComments,
        holdUntillDate: action.payload.initialRowApplyHold.holdUntillDate
      }
      return {
        ...state,
        modalData: {
          ...state.modalData,
          ApplyHoldRows
        }
      };
    }

    case UPDATE_LINE_CATEGORY: {
      let responseData = action.payload.initialRowLineNotes.lineCategory;
      let rowId = state.modalData.LineNotesRows.findIndex(r => r.id === action.payload.id);
      let LineNotesRows = [...state.modalData.LineNotesRows];
      LineNotesRows[rowId] = {
        ...LineNotesRows[rowId],
        ...state.modalData.LineNotesRows[rowId],
        lineCategory: responseData,
        lineNotesDataType: responseData.split('|')[1],
      }

      return {
        ...state,
        phase: SUCCESS,
        modalData: {
          ...state.modalData,
          LineNotesRows
        }
      };
    }

    case UPDATE_LINE_NOTES_ROW: {
      let rowId = state.modalData.LineNotesRows.findIndex(r => r.id === action.payload.id);
      let LineNotesRows = [...state.modalData.LineNotesRows];
      LineNotesRows[rowId] = {
        ...LineNotesRows[rowId],
        ...state.modalData.LineNotesRows[rowId],
        lineNoSeq: action.payload.initialRowLineNotes.lineNoSeq,
        lineNotesTitle: action.payload.initialRowLineNotes.lineNotesTitle,
        lineNotesDescription: action.payload.initialRowLineNotes.lineNotesDescription,
        lineNotesDataType: action.payload.initialRowLineNotes.lineNotesDataType,
        lineNotesComments: action.payload.initialRowLineNotes.lineNotesComments
      }
      return {
        ...state,
        modalData: {
          ...state.modalData,
          LineNotesRows
        }
      };
    }

    case GET_RMT_URD_DATA_SUCCESS:
      {
        let rows = state.modalData.rows;
        let rowId = action.id;
        rows = rows.map(row => {
          if (row.id === rowId) {
            row = { ...row }
            let tagItemsLength = row.urdData.map(x => Object.entries(x).filter(([key, value]) => key.startsWith('TAG_TYPE') && value !== '')).map(x => x.length);
            row.urdDetails = action.dataa.urdSimplifiedData;
            row.urdDetails.forEach((urdDetail, index) => {
              let tagLength = tagItemsLength[index];
              for (var i = 0; i < tagLength - 1; i++) {
                row.urdDetails = additionalTag(row, rowId);
              }
            })
          }
          return row;
        })
        return {
          ...state,
          modalData: {
            ...state.modalData,
            rows
          },
          phase: SUCCESS,
          error: null
        };
      }

    case CLEAR_APPLY_HOLDS: {
      let initialRowApplyHold = Object.assign({}, state.modalData.initialRowApplyHold);
      let ApplyHoldRows = [initialRowApplyHold];
      let initialRowLineNotes = Object.assign({}, state.modalData.initialRowLineNotes);
      let LineNotesRows = [initialRowLineNotes];
      return {
        ...state,
        modalData: {
          ...state.modalData,
          ApplyHoldRows,
          LineNotesRows
        }
      };
    }

    case ADDITION_BUTTON:
      {
        let { id } = action.payload;
        let _rows = [...state.modalData.rows];
        let rowIndex = _rows.findIndex(x => x.id === id);
        let urdDetails = additionalTag(_rows[rowIndex], id);
        _rows[rowIndex] = { ..._rows[rowIndex], urdDetails };
        return {
          ...state,
          modalData: {
            ...state.modalData,
            rows: _rows
          }
        }
      }

    case UPDATE_INITIAL_DATA:
      {
        let rows = state.modalData.rows;
        let rowId = action.id;
        let initialUrdData = {};
        rows = rows.map(row => {
          if (row.id === rowId) {
            row = { ...row }
            let urds = row.urdData;
            action.dataa.urdSimplifiedData.map(i => i.O_PSNODE_LIST.O_PSNODE_LIST_ITEM).flat().reduce((o, k) => {
              if (fieldMapping[k.NAME] !== undefined)
                o[fieldMapping[k.NAME]] = k.INITIAL_VALUE;
              return o;
            }, initialUrdData);
            let quantity = parseInt(state.modalData.rows[0].quantity);
            if (urds.length <= quantity) { //add more
              urds = urds.map((urd) => {
                Object.keys(initialUrdData).forEach(key => {
                  if (!Boolean(urd[key])) {
                    urd[key] = initialUrdData[key]
                  }
                })
                return urd;
              })
            } else { //delete
              urds = urds.slice((quantity - 1), urds.length - quantity)
            }
            row.urdData = urds;
            row.initialUrdData = initialUrdData;
          }
          return row;
        })
        return {
          ...state,
          modalData: {
            ...state.modalData,
            ...rows,
           // initialUrdData
          },
          phase: SUCCESS,
          error: null
        };
      }

    case UPDATE_URD_DISPLAY_DATA:
      let rowsForUpdate = [...state.modalData.rows];
      rowsForUpdate = rowsForUpdate.map(row => {
        if (row.id === action.payload.id) {
          row.urdDisplayData = action.payload.urdDisplayData
        }
        return row
      })
      return {
        ...state,
        modalData: {
          ...state.modalData,
          rows: rowsForUpdate,
        },
        phase: SUCCESS,
      }
//RT_002332 RT_002332 sudhakar (+)
case COPY_MFG_NOTES:
  return{
    ...state,
    phase: LOADING
  }
case COPY_MFG_NOTES_SUCCESS:
  {
    let id = action.id;
    let unit = action.unit;
    let _rows = [...state.modalData.rows];
    let rowIndex = _rows.findIndex(x => x.id === id);
  for (let i =unit;i<_rows[rowIndex].quantity;i++)
    {
    _rows[rowIndex].urdData[i].MFG_NOTES=_rows[rowIndex].urdData[unit].MFG_NOTES;
    } 
    toastr.success("Manufacturing notes successfully copied to below units", "Success");                 
    return {
      ...state,
      phase: SUCCESS,
      modalData: {
        ...state.modalData,
        rows: _rows
      }
    }        
  }

case COPY_TAG_DTLS:
  return{
    ...state,
    phase: LOADING
  }

case COPY_TAG_DTLS_SUCCESS:
  {
    let id = action.id;
    let unit = action.unit; 
    let _rows = [...state.modalData.rows];
    let rowIndex = _rows.findIndex(x => x.id === id);
    let rows = _rows.map(row => {
   
    let tagTypeLength = row.urdData.map(x => Object.entries(x).filter(([key, value]) => key.startsWith('TAG_TYPE') && value !== '')).length
   
    for (let i =unit;i<_rows[rowIndex].quantity;i++)
    {
      for (let j=1;j<=tagTypeLength;j++){ 
        if ((_rows[rowIndex].urdData[i][`TAG_TYPE${j}`] !== undefined) || (_rows[rowIndex].urdData[unit][`TAG_TYPE${j}`] !== undefined))
        {
          _rows[rowIndex].urdData[i][`TAG_TYPE${j}`]=_rows[rowIndex].urdData[unit][`TAG_TYPE${j}`];
        }
      }    

      let tagOptnLength = row.urdData.map(x => Object.entries(x).filter(([key,value]) => key.startsWith('TAG_OPTN') && value !== '')).length          

      for (let j=1;j<=tagOptnLength;j++){ 
        if ((_rows[rowIndex].urdData[i][`TAG_OPTN${j}`] !== undefined) || (_rows[rowIndex].urdData[unit][`TAG_OPTN${j}`] !== undefined))
        {
          _rows[rowIndex].urdData[i][`TAG_OPTN${j}`]=_rows[rowIndex].urdData[unit][`TAG_OPTN${j}`];
        }
      }

      let attachLength = row.urdData.map(x => Object.entries(x).filter(([key,value]) => key.startsWith('ATTACH')  && value !== '')).length
      
      for (let j=1;j<=attachLength;j++){ 
        if ((_rows[rowIndex].urdData[i][`ATTACH${j}`] !== undefined) || (_rows[rowIndex].urdData[unit][`ATTACH${j}`] !== undefined))
        {              
          _rows[rowIndex].urdData[i][`ATTACH${j}`]=_rows[rowIndex].urdData[unit][`ATTACH${j}`];
        }
      } 

      for (let j=1;j<=50;j++){ 
        //LINE_X
      if ((_rows[rowIndex].urdData[i][`LINE_${j}`] !== undefined) || (_rows[rowIndex].urdData[unit][`LINE_${j}`] !== undefined))
        {
          _rows[rowIndex].urdData[i][`LINE_${j}`]=_rows[rowIndex].urdData[unit][`LINE_${j}`];
        }                                                                                
      }                                        
    }          
    })                 
    toastr.success("Tag details successfully copied to below units", "Success");         
    return {
      ...state,
      phase: SUCCESS,
      modalData: {
        ...state.modalData,
        rows: _rows
      }
    }
  }

  case COPY_CALIB_DTLS:
    return{
      ...state,
      phase: LOADING
    }
  
  case COPY_CALIB_DTLS_SUCCESS:
    {
      let id = action.id;
      let unit = action.unit;      
      let _rows = [...state.modalData.rows];
      let rowIndex = _rows.findIndex(x => x.id === id);
      let rows = _rows.map(row => {    

      for (let i =unit;i<_rows[rowIndex].quantity;i++)
      {
        for (let j=1;j<=50;j++){ 
          //CAL-ITEMS
          if ((_rows[rowIndex].urdData[i][`CAL-ITEMS${j}`] !== undefined) || (_rows[rowIndex].urdData[unit][`CAL-ITEMS${j}`] !== undefined))
          {
            _rows[rowIndex].urdData[i][`CAL-ITEMS${j}`]=_rows[rowIndex].urdData[unit][`CAL-ITEMS${j}`];
          }
          //DESG
          if ((_rows[rowIndex].urdData[i][`DESG${j}`] !== undefined) || (_rows[rowIndex].urdData[unit][`DESG${j}`] !== undefined))
          {
            _rows[rowIndex].urdData[i][`DESG${j}`]=_rows[rowIndex].urdData[unit][`DESG${j}`];
          }
          //LRV
          if ((_rows[rowIndex].urdData[i][`LRV${j}`] !== undefined) || (_rows[rowIndex].urdData[unit][`LRV${j}`] !== undefined))
          {
            _rows[rowIndex].urdData[i][`LRV${j}`]=_rows[rowIndex].urdData[unit][`LRV${j}`];
          }
          //MEASUREMENT_TYPE
          if ((_rows[rowIndex].urdData[i][`MEASUREMENT_TYPE${j}`] !== undefined) || (_rows[rowIndex].urdData[unit][`MEASUREMENT_TYPE${j}`] !== undefined))
          {
            _rows[rowIndex].urdData[i][`MEASUREMENT_TYPE${j}`]=_rows[rowIndex].urdData[unit][`MEASUREMENT_TYPE${j}`];
          }
          //NP
          if ((_rows[rowIndex].urdData[i][`NP${j}`] !== undefined) || (_rows[rowIndex].urdData[unit][`NP${j}`] !== undefined))
          {
            _rows[rowIndex].urdData[i][`NP${j}`]=_rows[rowIndex].urdData[unit][`NP${j}`];
          } 
          //SQR
          if ((_rows[rowIndex].urdData[i][`SQR${j}`] !== undefined) || (_rows[rowIndex].urdData[unit][`SQR${j}`] !== undefined))
          {
            _rows[rowIndex].urdData[i][`SQR${j}`]=_rows[rowIndex].urdData[unit][`SQR${j}`];
          }
          //TBD
          if ((_rows[rowIndex].urdData[i][`TBD${j}`] !== undefined) || (_rows[rowIndex].urdData[unit][`TBD${j}`] !== undefined))
          {
            _rows[rowIndex].urdData[i][`TBD${j}`]=_rows[rowIndex].urdData[unit][`TBD${j}`];
          }
          //UOM
          if ((_rows[rowIndex].urdData[i][`UOM${j}`] !== undefined) || (_rows[rowIndex].urdData[unit][`UOM${j}`] !== undefined))
          {
            _rows[rowIndex].urdData[i][`UOM${j}`]=_rows[rowIndex].urdData[unit][`UOM${j}`];
          }
          //URV
          if ((_rows[rowIndex].urdData[i][`URV${j}`] !== undefined) || (_rows[rowIndex].urdData[unit][`URV${j}`] !== undefined))
          {
            _rows[rowIndex].urdData[i][`URV${j}`]=_rows[rowIndex].urdData[unit][`URV${j}`];
          }   
          //Device Code
          if ((_rows[rowIndex].urdData[i][`ASSOC${j}`] !== undefined) || (_rows[rowIndex].urdData[unit][`ASSOC${j}`] !== undefined))
          {
            _rows[rowIndex].urdData[i][`ASSOC${j}`]=_rows[rowIndex].urdData[unit][`ASSOC${j}`];
          }                                                                                             
        }                                        
      }          
      })
      toastr.success("Calibration details successfully copied to below units", "Success");

      return {
        ...state,
        phase: SUCCESS,
        modalData: {
          ...state.modalData,
          rows: _rows
        }
      }
    }  

//RT_002332 RT_002332 sudhakar (-)      
    case UPDATE_REQUIRED_MULTI_LEVEL_MODEL:
      return {
        ...state,
        requiredMultiLevelModel: action.payload.requiredMultiLevelModel,
      };
      
    default:
      return state;
  }
}

// Action creator -- addRow
export const addRow = payload => ({
  type: ADD_ROWS,
  payload
});

// Action creator -- updateModel
export const updateModel = payload => ({
  type: UPDATE_MODEL,
  payload
});

// Action creator -- updateURD
export const updateURD = payload => ({
  type: UPDATE_URD,
  payload
});

// Action creator -- deleteRow
export const deleteRow = payload => ({
  type: DELETE_ROWS,
  payload
});

// Action creator -- getModelString
export const getModelString = payload => ({
  type: GET_MODELSTRING,
  payload
});

// Action creator -- getModelName
export const getModelName = payload => ({
  type: GET_MODELNAME,
  payload
});

// Action creator -- getWareHouse
export const getWareHouse = payload => ({
  type: GET_WAREHOUSE,
  payload
});

// Action creator -- getModifier
export const getModifier = payload => ({
  type: GET_MODIFIERS,
  payload
});

// Action creator -- getInline
export const getInline = payload => ({
  type: GET_INLINE_VALIDATION,
  payload
});

// Action creator -- getInsert
export const getInsert = payload => ({
  type: GET_INSERT_DATA,
  payload
});

// Action creator -- InsertEmail
export const insertEmail = payload => ({
  type: EMAIL_NOTIFICATION,
  payload
});

// Action creator -- importExcel
export const importExcel = payload => ({
  type: IMPORT_EXCEL_DATA,
  payload
})

// Action creator -- importPasteList
export const importPasteList = payload => ({
  type: IMPORT_PASTELIST,
  payload
})

// Action creator -- getUrd
export const getUrd = payload => ({
  type: GET_URD_DATA,
  payload
});

// Action creator -- getLocation
export const getLocation = payload => ({
  type: GET_USER_LOCATION,
  payload
});

// Action creator -- clearBaseModels
export const clearBaseModels = payload => {
  return {
    type: CLEAR_BASE_MODELS_SUCCESS,
    id: payload
  };
};

// Action creator -- urdInsert
export const urdInsert = payload => ({
  type: UPDATE_URD_INSERT_DATA,
  payload
});

// Action creator -- clearCurrentModelName
export const clearCurrentModelName = payload => ({
  type: CLEAR_MODELNAME_SUCCESS,
  payload
});

// Action creator -- removeSaveErrors
export const removeSaveErrors = payload => ({
  type: CLEAR_SAVE_ERROR,
  payload
});

// Action creator -- quantityOnChange
export const quantityOnChange = payload => ({
  type: QUANTITY_CHANGE_SUCCESS,
  payload
});

// Action creator -- getEditData
export const getEditData = payload => ({
  type: GET_EDIT_DATA,
  payload
});

// Action creator -- clearUrdDataOnChange
export const clearUrdDataOnChange = payload => ({
  type: CLEAR_URD_DATA_SUCCESS,
  payload
});

// Action creator -- addNewRow
export const addNewRow = payload => ({
  type: ADD_NEW_ROW,
  payload
});

// Action creator -- getTaskNumber
export const getTaskNumber = payload => ({
  type: TASK_NUMBER,
  payload
});

// Action creator -- updateInfoRow
export const updateInfoRow = payload => ({
  type: UPDATE_ROW,
  payload
});

// Action creator -- handlepickchange
export const handlepickchange = payload => ({
  type: HANDLE_PICK_CHANGE,
  payload
});

// Action creator -- additionalInfo
export const additionalInfo = payload => ({
  type: ADDITIONAL_INFO,
  payload
});

// Action creator -- addApplyholds
export const addApplyholds = payload => ({
  type: ADD_ROWS_APPLY_HOLDS,
  payload
});

// Action creator -- getLinePrice
export const getLinePrice = payload => ({
  type: LINE_PRICE,
  payload
});

// Action creator -- addLineNotes
export const addLineNotes = payload => ({
  type: ADD_ROWS_LINE_NOTES,
  payload
});

// Action creator -- updateLineHold
export const updateLineHold = payload => ({
  type: UPDATE_LINE_HOLD,
  payload
});

// Action creator -- updateHoldRow
export const updateHoldRow = payload => ({
  type: UPDATE_HOLD_ROW,
  payload
});

// Action creator -- getRmtUrd
export const getRmtUrd = payload => ({
  type: GET_RMT_URD_DATA,
  payload
});

// Action creator -- updateLineCategory
export const updateLineCategory = payload => ({
  type: UPDATE_LINE_CATEGORY,
  payload
})

// Action creator -- updateLineNotesRow
export const updateLineNotesRow = payload => ({
  type: UPDATE_LINE_NOTES_ROW,
  payload
})

// Action creator -- clearHoldRow
export const clearHoldRow = () => ({
  type: CLEAR_APPLY_HOLDS
})

// Action creator -- updateInitialURD
export const updateInitialURD = () => ({
  type: UPDATE_INITIAL_DATA
})

// Action creator -- updateUrdDisplayData
export const updateUrdDisplayData = payload => ({
  type: UPDATE_URD_DISPLAY_DATA,
  payload
})

// Action creator -- getPriceRollUpData
export const getPriceRollUpData = payload => ({
  type: GET_PRICE_ROLLUP_DATA,
  payload
})

// Action creator -- getAssembleData
export const getAssembleToData = payload => ({
  type: GET_ASSEMBLETO,
  payload
})

// Action creator -- priceDataSave
export const priceDataSave = payload => ({
  type: SAVE_PRICEROLL,
  payload
})

// Action creator -- updateRequiredMultiLevelModel
export const updateRequiredMultiLevelModel = payload => ({
  type: UPDATE_REQUIRED_MULTI_LEVEL_MODEL,
  payload
})


