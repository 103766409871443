import React, { Component } from 'react';
import TextInput from '../../shared/TextInput';
import SelectInput from '../../shared/SelectInput';
import { getURLDetails } from "../../../utils/methods";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
//changes are made for the RFC AGL-ORA000231
class AdditionalInfo extends Component {

    constructor() {
        super();
        this.state = { tabIndex: 0 };
    }
    state = { // initial state
        disableFlag: true
    }

    // ===== Onchange event which triggers thsi function to get the task number =====
    getTaskNum = (value) => {
        const { getTaskNumberData } = this.props;
        let taskId = value.split('|')[1];
        getTaskNumberData(taskId);
    }

    render() {
        const { id, data, taskNumberData, seedData } = this.props; // passing the props to render method
        const { disableFlag } = this.state; // passing the state to render method
        const appId = getURLDetails().I_APP_CODE;
        return (
            <div className="row additionalInfo-sec">
                <Tabs className="col-12" selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.setState({ tabIndex })}>
                    <div className="row">
                        <div className="col-2">
                            {/* Verticle tab list */}
                            <TabList>
                                <Tab><b>Main</b></Tab>
                                {this.props.id ===0 ? appId === "ONT" ? <Tab><b>Shipping</b></Tab> : "" : ""} {/* added by sudhakar RT_002477 RT_002336 */}
                                {/*{this.props.id === 0 ? <Tab><b>Services</b></Tab> : ""} {/* added by sudhakar RT_002477 RT_002336 */}
                                <Tab><b>Services</b></Tab>
                                <Tab><b>Others</b></Tab>
                            </TabList>
                        </div>

                        <div className="col-10">
                            {/* Tab panels  */}  {/* added disabled={(this.props.id != 0)} for other fields in main and others by sudhakar RT_002477 RT_002336 */}
                            <TabPanel>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <div className="row additionalInfo-field">
                                            <label className="col-xs-6 col-sm-6 col-md-3 col-lg-3 form-label">
                                                Line Type
                                                </label>
                                            <div className="col-xs-6 col-sm-6 col-md-8 col-lg-8">
                                                <SelectInput name="lineType" defaultOption="--Select Line Type--"
                                                    value={data.lineType}
                                                    options={seedData.lineTypeData} disabled={(this.props.id !== 0)}
                                                    onChange={e => this.onAdditionalInfoChange(e, id)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {appId === "ONT" ?
                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                            <div className="row additionalInfo-field">
                                                <label className="col-xs-6 col-sm-6 col-md-5 col-lg-5 form-label">
                                                    Customer PO Line Number
                                                </label>
                                                <div className="col-xs-6 col-sm-6 col-md-7 col-lg-7">
                                                    <TextInput name="customerpolineNum" type="text" value={data.customerpolineNum}
                                                        placeholder="Customer PO Line Number" onChange={e => this.onAdditionalInfoChange(e, id)} />
                                                </div>
                                            </div>
                                        </div> : " "}

                                    {appId === "ONT" ?
                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                            <div className="row additionalInfo-field">
                                                <label className="col-xs-6 col-sm-6 col-md-3 col-lg-3 pr-0 form-label">
                                                    QS Cause Code
                                                </label>
                                                <div className="col-xs-6 col-sm-6 col-md-8 col-lg-8">
                                                    <TextInput name="qscausecode" type="text" value={data.qscausecode} disabled={(this.props.id !== 0)}
                                                        placeholder="QS Cause Code" onChange={e => this.onAdditionalInfoChange(e, id)} />
                                                </div>
                                            </div>
                                        </div> : ""}
                                    {appId === "ONT" ?
                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                            <div className="row additionalInfo-field">
                                                <label className="col-xs-6 col-sm-6 col-md-5 col-lg-5 form-label">
                                                    Pick Pack Lead Time
                                                </label>
                                                <div className="col-xs-6 col-sm-6 col-md-7 col-lg-7">
                                                    <TextInput name="pickPackTime" type="number"
                                                        value={data.pickPackTime}
                                                        min={1}
                                                        placeholder="Pick Pack Lead Time" disabled={(this.props.id !== 0)}
                                                        onChange={e => { this.onAdditionalInfoChange(e, id); this.handlepickChange(e, id) }} />
                                                </div>
                                            </div>
                                        </div> : " "}
                                    {appId === "ONT" ?
                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                            <div className="row additionalInfo-field">
                                                <label className="col-xs-6 col-sm-6 col-md-5 col-lg-5 form-label">
                                                    Rep Line Number
                                                </label>
                                                <div className="col-xs-6 col-sm-6 col-md-7 col-lg-7">
                                                    <TextInput name="Rep Line Number" 
                                                    type="text" onChange={e => this.onAdditionalInfoChange(e, id)} />
                                                </div>
                                            </div>
                                        </div> : " "}
{/* USER DESC*/}
                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                            <div className="row additionalInfo-field">
                                                <label className="col-xs-6 col-sm-6 col-md-3 col-lg-3 pr-0 form-label">
                                                    User Item Description
                                                </label>
                                                <div className="col-xs-6 col-sm-6 col-md-8 col-lg-8">
                                                    <TextInput name="userItemDescription" type="text" value={data.userItemDescription}
                                                        placeholder="User Item Description" onChange={e => this.onAdditionalInfoChange(e, id)} />
                                                </div>
                                            </div>
                                        </div>                         

                                </div>
                            </TabPanel>
                             {/* added by sudhakar RT_002477 RT_002336*/}
                             {this.props.id ===0 ? appId === "ONT" ? 
                                <TabPanel>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                            <div className="row additionalInfo-field">
                                                <label className="col-xs-6 col-sm-6 col-md-4 col-lg-4 form-label">
                                                    Ship Set
                                        </label>
                                                <div className="col-xs-6 col-sm-6 col-md-8 col-lg-8">
                                                    <TextInput name="shipSet" type="number"
                                                        value={data.shipSet}
                                                        min={1}
                                                        placeholder="Ship Set"
                                                        onChange={e => { this.onAdditionalInfoChange(e, id); this.handlepickChange(e, id) }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                            <div className="row additionalInfo-field">
                                                <label className="col-xs-6 col-sm-6 col-md-4 col-lg-4 form-label">
                                                    Fulfillment Set
                                        </label>
                                                <div className="col-xs-6 col-sm-6 col-md-8 col-lg-8">
                                                    <TextInput name="fullfillmentSet" type="number"
                                                        value={data.fullfillmentSet}
                                                        min={1}
                                                        placeholder="Fulfillment Set"
                                                        onChange={e => { this.onAdditionalInfoChange(e, id); this.handlepickChange(e, id) }} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                            <div className="row additionalInfo-field">
                                                <label className="col-xs-6 col-sm-6 col-md-4 col-lg-4 form-label">
                                                    Shipping Instruction
                                        </label>
                                                <div className="col-xs-6 col-sm-6 col-md-8 col-lg-8">
                                                    <TextInput name="shippingInstructions" type="text"
                                                        value={data.shippingInstructions}
                                                        placeholder="Shipping Instruction"
                                                        onChange={e => this.onAdditionalInfoChange(e, id)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                            <div className="row additionalInfo-field">
                                                <label className="col-xs-6 col-sm-6 col-md-4 col-lg-4 form-label">
                                                    Packing Instructions
                                        </label>
                                                <div className="col-xs-6 col-sm-6 col-md-8 col-lg-8">
                                                    <TextInput name="packingInstructions" type="text"
                                                        value={data.packingInstructions}
                                                        placeholder="Packing Instructions"
                                                        onChange={e => this.onAdditionalInfoChange(e, id)} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                            <div className="row additionalInfo-field">
                                                <label className="col-xs-6 col-sm-6 col-md-4 col-lg-4 form-label">
                                                    Console Location
                                        </label>
                                                <div className="col-xs-6 col-sm-6 col-md-8 col-lg-8">
                                                    <SelectInput name="consoleAddress" value={data.consoleAddress}
                                                        defaultOption="--Select Console Location--" options={seedData.consoleLocationData}
                                                        onChange={e => this.onAdditionalInfoChange(e, id)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                            <div className="row additionalInfo-field">
                                                <label className="col-xs-6 col-sm-6 col-md-4 col-lg-4 form-label">
                                                    Source Type
                                        </label>
                                                <div className="col-xs-6 col-sm-6 col-md-8 col-lg-8">
                                                    <SelectInput name="sourceType" value={data.sourceType}
                                                        defaultOption="--Select Source Type--" options={seedData.sourceTypeData}
                                                        onChange={e => this.onAdditionalInfoChange(e, id)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                : " " : " "}
                            {/* added by sudhakar RT_002477 RT_002336*/}  
                            {/*</div>{this.props.id ===0 ? appId !== "ONT123" ?*/}
                                <TabPanel>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <div className="row additionalInfo-field">
                                        <label className="col-xs-6 col-sm-6 col-md-3 col-lg-3 form-label">
                                            KOB
                                    </label>
                                        <div className="col-xs-6 col-sm-6 col-md-9 col-lg-9">
                                            <SelectInput name="kobDetails" value={data.kobDetails}
                                                defaultOption="--Select KOB--" options={seedData.kobData}
                                                placeholder="KOB"
                                                onChange={e => this.onAdditionalInfoChange(e, id)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                </TabPanel>
                                {/*: " " : " "}*/}
                            <TabPanel>
                                <div className="row">

                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <div className="row additionalInfo-field">
                                            <label className="col-xs-6 col-sm-6 col-md-4 col-lg-4 form-label">
                                                Customer Reference
                                        </label>
                                            <div className="col-xs-6 col-sm-6 col-md-8 col-lg-8">
                                                <TextInput name="customerRef" value={data.customerRef}
                                                    placeholder="Customer Reference"
                                                    type="text" onChange={e => this.onAdditionalInfoChange(e, id)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {appId === "ONT" && (
                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                            <div className="row additionalInfo-field">
                                                <label className="col-xs-6 col-sm-6 col-md-4 col-lg-4 form-label">
                                                    Item Type
                                        </label>
                                                <div className="col-xs-6 col-sm-6 col-md-8 col-lg-8">
                                                    <SelectInput name="itemType" defaultOption="--Select Item Type--"
                                                        value={data.itemType} disabled={(this.props.id !== 0)}
                                                        options={seedData.itemTypeData} onChange={e => this.onAdditionalInfoChange(e, id)}
                                                        //disabled={seedData.itemTypeFlagData === "Y" ? "" : { disableFlag }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <div className="row additionalInfo-field">
                                            <label className="col-xs-6 col-sm-6 col-md-4 col-lg-4 form-label">
                                                Project Number
                                        </label>
                                            <div className="col-xs-6 col-sm-6 col-md-8 col-lg-8">
                                                <SelectInput name="projectNum" value={data.projectNum}
                                                    defaultOption="--Select Project Number--" options={seedData.projectNumberData} disabled={(this.props.id !== 0)}
                                                    onChange={e => { this.onAdditionalInfoChange(e, id); this.getTaskNum(e.target.value) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <div className="row additionalInfo-field">
                                            <label className="col-xs-6 col-sm-6 col-md-4 col-lg-4 form-label">
                                                Task Number
                                        </label>
                                            <div className="col-xs-6 col-sm-6 col-md-8 col-lg-8">
                                                <SelectInput name="taskNum" value={data.taskNum}
                                                    defaultOption="--Select Task Number--" options={taskNumberData} disabled={(this.props.id !== 0)}
                                                    onChange={e => this.onAdditionalInfoChange(e, id)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <div className="row additionalInfo-field">
                                            <label className="col-xs-6 col-sm-6 col-md-4 col-lg-4 form-label">
                                                Customs Value
                                    </label>
                                            <div className="col-xs-6 col-sm-6 col-md-8 col-lg-8">
                                                <TextInput name="customsvalue" value={data.customsvalue} disabled={(this.props.id !== 0)}
                                                    placeholder="Customs Value" type="text" onChange={e => this.onAdditionalInfoChange(e, id)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </div>
                    </div>
                </Tabs>
            </div>
        );
    }

    // ===== Function triggred when onChange is called ====
    onAdditionalInfoChange = (event, id) => {
        const name = event.target.name;
        const value = event.target.value;
        this.updateRowsContent(id, name, value);
    }

    // ===== To update the rows of additional info =====
    updateRowsContent = (id, name, value) => {
        if (typeof this.props.data === "object") {
            let additionalData = Object.assign(
                {},
                JSON.parse(JSON.stringify(this.props.data))
            );
            additionalData[name] = value;
            this.props.updateRow({ id, additionalData });
        }
    };

    // ===== To capture the pick pack lead time =====
    handlepickChange = (event, id) => {
        const name = event.target.name;
        const value = event.target.value;
        this.props.onHandlepickChange(value);
        this.updateRowsContent(id, name, value);
    }
}

export default AdditionalInfo;